<template>
  <div class="over">
    <v-alert
      v-model="error.value"
      :value="error.value"
      :type="error.type"
      :icon="error.icon ? error.icon : null"
      :transition="error.transition"
      dismissible
      dense
    >
      {{ error.message }}
    </v-alert>
  </div>
</template>

<script>
import store from "../store"

export default {
	name: "StackAlert",
	computed: {
		error() {
			return this.$store.getters.error
		}
	}
}
</script>

<style scoped lang="stylus">
	.over {
		position: fixed;
		top: 0;
		width: 100%;
	}
</style>
