export const fieldDefinition = [ 'description', 'id', 'needed', 'type', 'validation']
export const customValidatorConstants = {
	clientIdentifier: 'required|clientIdentifier:3,50',
	name: 'required|name:3,50',
	projectName: 'required|any:3,50',
	slug: 'required|slug',
	JSON: 'required|JSON',
}
export const quillEditorOptions = [
	[{ size: ['small', false, 'large'] }],
	['bold', 'italic'],
	[{'script': 'sub'}, {'script': 'super'}],
	[{ list: 'ordered'}, { list: 'bullet' }],
	['link', 'image'],
	[{color: []}, {background: []}],
	[{ 'align': null}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
	[{ font: [] }],
	['clean']
]
export const conditionNames = {
	'greater_than': '>',
	'less_than': '<',
	'greater_or_equal_to': '>=',
	'less_or_equal_to': '<=',
	'between': '..'
}
