var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "over" },
    [
      _c(
        "v-alert",
        {
          attrs: {
            value: _vm.error.value,
            type: _vm.error.type,
            icon: _vm.error.icon ? _vm.error.icon : null,
            transition: _vm.error.transition,
            dismissible: "",
            dense: "",
          },
          model: {
            value: _vm.error.value,
            callback: function ($$v) {
              _vm.$set(_vm.error, "value", $$v)
            },
            expression: "error.value",
          },
        },
        [_vm._v(" " + _vm._s(_vm.error.message) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }