<template>
  <v-app>
    <div class="aaa">
      <component :is="layout">
        <router-view :layout.sync="layout" />
      </component>
    </div>
    <StackAlert />
    <v-snackbar
      :value="message"
      color="success"
    >
      {{ message }}
    </v-snackbar>
  </v-app>
</template>

<script>
import StackAlert from "./components/StackAlert"

export default {
	name: 'App',
	components: {StackAlert},
	apollo: {
		client: {
			query: require("@/apollo/queries/CURRENT_CLIENT.gql"),
			result({data, loading}) {
			},
			update({response}) {
			  if (!response) return null
				const {id, identifier} = response
				this.$store.dispatch('setClient', {
					id,
					identifier
				})
			}
		}
	},
	data() {
		return {
			layout: 'div',
		}
	},
	computed: {
		message() {
			return this.$store.getters.toolbarMessage
		},
	}
}
</script>

<style lang="stylus">
	html, body
		overflow: hidden !important
		height: 100%
		width: 100%
	.aaa
		position: fixed
		left: 0
		right: 0
		top: 54px
		height: calc(100% - 54px)
		overflow-y: auto
</style>
