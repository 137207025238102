import store from '../store'

export const Error = (() => ({
	test: (condition, message, options) => {
		try {
			if (condition) throw new Error.standard({ message, options })
		} catch (e) {
			return true
		}
	},
	graphQL: error => {
		store.commit('error', error)
		return error
	},
	standard: error => {
		store.commit('error', error)
		return error
	}
}))()
