import Vue from 'vue'
import { convertToColumnOfButtons } from '@/JS/utils'
import { Error } from '@/JS/Error'
import * as schemasByModel from '@/apollo/queries/SCHEMA_BY_MODEL.gql'
import * as clientList from '@/apollo/queries/CLIENT_INDEX.gql'
import * as contentTypes from '@/apollo/queries/CONTENT_FOR_STEP.gql'
import * as textContents from '@/apollo/queries/CONTENT_TEXT_INDEX.gql'
import * as clientMedias from '@/apollo/queries/CLIENT_MEDIA_INDEX.gql'
import * as clientMediasToDropDown from '@/apollo/queries/CLIENT_MEDIA_INDEX.gql'
import * as textContentIndex from '@/apollo/queries/CONTENT_TEXT_INDEX.gql'

const metaData = function({ response = {} }) {
	return function(object) {
		const { meta, data } = response
		if (
			meta
			&& object.meta
			&& this.$data.meta
		) {
			const { count, limit, page } = meta
			if (count || limit || page ) {
				Vue.set(this.$data.meta.pagination, object.meta, meta)
			} else {
				Vue.set(this.$data.meta, object.meta, meta)
			}
		}
		if (
			data
			&& object.setData
		) {
			object.setData(data)
		}
		return data || response || []
	}.bind(this)
}

const result = data => {
	const { error } = data
	if (
		error
		&& error.graphQLErrors
	) {
		Error.graphQL(error)
	}
	return data
}

const setModelMeta = function({ meta, modelMeta }) {
	if (meta && modelMeta) {
		Vue.set(this.$data.meta, meta, modelMeta)
	}
}

export const treatSchemaResponse = function({ store, meta, property, response: { modelMeta, schemas } }) {
	if (store) {
		const { hasTags, hasLayout } = modelMeta
		this.$store.commit(`${store}/setTags`, { data: hasTags, property })
		if (hasLayout && hasLayout.length) this.$store.commit(`${store}/layoutsSetArray`, { data: hasLayout })
		this.$store.dispatch(`${store}/setModelMeta`, { data: modelMeta })
	} else if (meta) {
		setModelMeta.call(this, { meta, modelMeta })
	}
	return convertToColumnOfButtons(schemas)
}

export const SchemasByModel = ({ model, meta = null, store = null, property = null }) => ({
	query: schemasByModel,
	variables: {
		model
	},
	result: data => result(data),
	update({ response }) {
		return treatSchemaResponse.call(this,{ store, meta, property, response })
	}
})

const meta = function({ limit = 25, page = 1, ids = null }) {
	return {
		limit,
		page,
		ids
	}
}

export const ClientList = ({ limit = 20, page = 1, ids = null }) => ({
	query: clientList,
	variables: {
		limit,
		page,
		ids
	},
	result: data => result(data),
	update({response}) {
		return metaData.call(this, { response })({ meta: 'clientList' })
	}
})

export const fetchMore = function({ query, page }) {
	query.fetchMore({
		variables: {
			page
		},
		updateQuery: (previous, { fetchMoreResult }) => ({
			response: fetchMoreResult.response
		})
	})
}

export const ContentTypes = (data = {}) => ({
	query: contentTypes,
	variables: meta(data),
	result: data => result(data),
	update({ response }) {
		return convertToColumnOfButtons(response || [])
	}
})

export const ClientMedias = ({ limit, page = 1, ids = null, tooltip = false, func = null, pagination = '' }) => ({
	query: clientMedias,
	variables: {
		limit,
		page,
		ids
	},
	result: data => result(data),
	update({ response}) {
		const { data, meta } = response
		if (pagination && meta) Vue.set(this.$data.pagination, pagination, meta)
		metaData.call(this, { response })({ meta: 'medias' })
		if (func) return func(data || [])
		return data || []
	}
})

export const ClientMediasIndex = ({ limit, page = 1, ids = null, tooltip = false } = {}) =>
	ClientMedias({ limit, page, ids, tooltip, func: x => convertToColumnOfButtons(x) })

export const ClientMediasToDropDown = (objectPath = '') => ({
	query: clientMediasToDropDown,
	result: data => result(data),
	update({response: { data, meta }}) {
		if (meta && this.$data.meta) this.$data.meta.medias = meta
		return convertToColumnOfButtons(data || [], { text: 'slug' })
	}
})

export const TextContentIndex = ({ limit, page = 1, ids = null, tooltip = false }) => ({
	query: textContentIndex,
	variables: {
		limit,
		page,
		ids
	},
	result: data => result(data),
	update({ response }) {
		const { data, meta } = response
		metaData.call(this, { response })({ meta: 'textContents' })
		return convertToColumnOfButtons(data || [], { text: 'slug', tooltip })
	}
})

export const SchemaTags = ({ model, store }) => ({
	query: schemasByModel,
	variables: {
		model
	},
	result: data => result(data),
	update({ response : { modelMeta, schemas } }) {
		const { hasTags } = modelMeta
		this.$store.commit(`${store}/setTags`, { data: hasTags })
	}
})

export const columnOfButtons = ({
	limit,
	page = 1,
	ids = null,
	tooltip = false,
	query = null,
	text = 'slug',
	id,
	pagination = '',
	transform,
	dataFunc = x => x,
	include = null,
	dropDown = false,
	namespace = ''
}) => {
	if (!query) return []
	return {
		query,
		variables: {
			limit,
			page,
			ids
		},
		result: data => result(data),
		update({response}) {
			let {data, elementType, meta} = response
			if (!data && Array.isArray(response)) data = response
			if (pagination && meta) Vue.set(this.$data.pagination, pagination, meta)
			if (meta?.editor && namespace) {
				this.$store.commit(`${namespace}/setProperty`, { property: 'meta.editor', value: meta.editor })
			}
			const result = convertToColumnOfButtons(dataFunc(data) || [], {text, id, tooltip, transform, include})
			const a = dropDown
				? result
					.reduce((a, c) => {
						const { text } = c
						return text
							? [ ...a, {
								text,
								value: text,
							}]
							: a
					}, [])
				: result
			return a
		}
	}
}
