import isPlainObject from 'is-plain-object'
import {camelCase, cloneDeep, isBoolean, isEmpty, kebabCase, isObject as lodashIsObject, set } from 'lodash'
import store from '../store'

/**
 * Assign a passed function to each object's property
 * @param object
 * @returns {function(*): ({} & {[p: string]: *})}
 */
export const onObjectKeysApply = object => func => {
	return Object.keys(object).reduce((a, key) => {
		return Object.assign(a, {
			[key]: func(object[key])
		})
	}, {})
}

/**
 * Verify if all object's properties have value
 * @param object
 * @param properties
 * @returns {*}
 */
export const isAllPropertiesHaveValue = (object, properties) =>
	object.reduce((a, c) => !!properties[c] && a, true)

/**
 * Assign a starting value to a variable and, after the desired period, assign a ending value
 * N.B. to be called with context
 * @param variable
 * @returns function for curying
 */
export const setTimeoutOn = function (variable) {
	return function (startValue, endValue, nbMilliseconds = 3000) {
		this[variable] = startValue
		setTimeout(() => {
			this[variable] = endValue
		}, nbMilliseconds)
	}.bind(this)
}

/**
 *  Verify, inside the passed object, if there is at least one property that is a plain object
 *  Especially used to limit recursion inside objects properties
 * @param object
 * @returns {boolean}
 */
export const isObjectInside = object =>
	Object.keys(object).reduce(
		(a, key) => isPlainObject(object[key] || a),
		false
	)

/**
 * Set a value to a deep object tree property
 * Filtered "by" passed function
 * @param tree
 * @param value
 * @param by
 * @returns {*}
 */
export const setTreeDeepValueBy = (tree, value, by = x => false) => tree
	.reduce((a, c) => {
		if (c.subjectsToH && c.subjectsToH.length) {
			return [
				...a,
				Object.assign(c, {
					active: by(c) && value,
					subjectsToH: setTreeDeepValueBy(c.subjectsToH, value, by)
				})
			]
		}
		return [
			...a,
			Object.assign(c, {
				active: by(c) && value
			})
		]
	}, [])

/**
 * Delete a deep object property
 * Filtered "by" passed function
 * @param tree
 * @param by
 * @returns {*}
 */
export const deleteTreeDeepNodeBy = (tree, by) => tree
	.reduce((a, c) => {
		if (by(c)) {
			return a
		}
		if (c.subjectsToH && c.subjectsToH.length) {
			return [
				...a,
				Object.assign(c, {
					subjectsToH: deleteTreeDeepNodeBy(c.subjectsToH, by)
				})
			]
		}
		return [...a, c]
	}, [])

export const stackStringnify = x => JSON.stringify(x)

/**
 * Convert plain identifier to Stack Identifier Format
 * Presently kebab case
 * @param x
 * @returns {string}
 */
export const stackIdentifier = x => kebabCase(x)

/**
 * Extract data from schema
 * @param schema
 * @returns {{} & {[p: string]: *}}
 */
export const extractDataFromScheme = schema => Object.keys(schema)
	.reduce((a, key) => Object.assign(a, {
		[key]: schema[key].value
	}), {})

export const isBetweenEqual = (value = value.toString(), min, max) => value >= min && value <= max
export const isBetween = (value = value.toString(), min, max) => value > min && value < max

/**
 * Add properties to each object of array, to permit use in draggable nested tree view
 * properties : option_text, option_id and children (children allows nesting)
 * @param x
 */
export const convertToDraggableNestedTreeView = array => {
	if (!Array.isArray(array)) return array
	return array
		.map(x => Object.assign(x, {
			option_text: x.slug,
			option_id: x.id,
			children: []
		}))
}

/**
 * Returns only options text & id for dropdown select from array of objects
 * @param array
 * @returns {*}
 */
export const convertToColumnOfButtons = (array, {text, id, tooltip = false, transform = null, include = null} = {}) => {
	if (!Array.isArray(array)) return array
	return array
		.map(x => {
			const result = {
				option_text: text ? x[text] : (x.slug || x),
				option_id: id ? x[id] : (x.id || x),
				tooltip: tooltip ? x.html : '',
				elementType: x.elementType || null,
				url: x.url || ''
			}
			if (include) {
				Object.keys(include)
					.reduce((a, key) =>
						x[key]
							? Object.assign(a, { [key]: x[key]})
							: a, result)
			}
			return transform ? transform(x) : result
		})
}

export const transformConditions = object => result => ({
	option_text: (x => {
		return Object.keys(object)
			.reduce((a, key) => (a.includes(key) ? `${a.split(key).shift().replace('_', '')} ${object[key]}` : a), result.name.toLowerCase())
	})(result),
	option_id: result.name,
	tooltip: result.name,
	type: Object.keys(object).reduce((a, key) => a.includes(key) ? object[key] : a, result.name.toLowerCase()),
	params: Object.keys(result.params).reduce((a, key) => Object.assign(a, {[key]: null}), {})
})


/**
 * Assign properties to a given object with variables object
 * variables is a named arguments object, constituted by variables
 * @param object
 * @param variables
 * @returns {*}
 */
export const assignPropertiesToObjectOnlyWhenVarHasValues = (object, variables) => {
	const clone = cloneDeep(object)
	Object.keys(variables)
		.forEach(key => {
			if (
				!isEmpty(variables[key])
				|| Array.isArray(variables[key])
			) {
				Object.assign(clone, {
					[key]: variables[key]
				})
			}
		})
	return clone
}

/**
 * GraphQL related
 * To enclose all variables passed to mutations in a 'input' property
 * @param data
 * @returns {{input: *}}
 */
export const encloseInInput = data => ({input: data})

/**
 * Check if x, converted as String, is a html content, then returns true/false
 * @param x
 * @returns {boolean}
 */
export const isHTML = x => /^<\/?[a-z][\s\S]*>/i.test(String(x))

/**
 * check if String (contentType syntax) is image
 * @param x
 * @returns {boolean}
 */
export const isImage = x => /^image\/.*$/.test(String(x))

/**
 * check if String is a valid JSON document
 * @param x
 * @returns {boolean}
 */
export const isJSONObject = x => {
	try {
		return lodashIsObject(JSON.parse(x))
	} catch(e) {
		return false
	}
}

/**
 * check if String (contentType syntax) is JSON
 * @param x
 * @returns {boolean}
 */
export const isJSON = x => /^.*\/json$/.test(String(x))

/**
 * check if String (contentType syntax) is SVG
 * @param x
 * @returns {boolean}
 */
export const isSVG = x => /^.*svg.*$/.test(String(x))

/**
 * check if String (contentType syntax) is PDF
 * @param x
 * @returns {boolean}
 */
export const isPDF = x => /^.*pdf.*$/.test(String(x))

/**
 * check if object has a value at the end of the submitted dot syntax path
 * @param object
 * @param path
 * @returns {boolean}
 */
export const isValueOnPath = (object = {}, path = []) => !!path.split('.').reduce((a, c) => (a && a[c] ? a[c] : false), object)

/**
 * get the value at the end of object's path
 * @param object
 * @param path
 * @returns {*}
 */
export const getValueOnPath = (object = {}, path = []) => path.split('.').reduce((a, c) => (a && a[c] ? a[c] : null), object)

/**
 * Purify object of any __typename property and get filtered result (i.e. 'data' or 'content' property) to assign it at a lower level
 * @param object
 * @returns {{__typename}|*}
 */
export const purify = object => {
	if (!object) return object
	const DATA = 'data'
	const CONTENT = 'content'
	const BLOCK = 'block'
	const filter = property => x => x && x[property] ? x[property] : x
	const captureFilters = {
		accepted: filter(DATA),
		answers: filter(DATA),
		children: filter(DATA),
		clientCards: filter(DATA),
		clientCategories: filter(DATA),
		clientCriterias: filter(DATA),
		clientDeck: filter(DATA),
		clientDecks: filter(DATA),
		clientFiles: filter(DATA),
		clientGames: filter(DATA),
		clientMedias: filter(DATA),
		clientModules: filter(DATA),
		clientRewards: filter(DATA),
		contentTexts: filter(DATA),
		entry: filter(BLOCK),
		formElements: filter(DATA),
		formattedElements: filter(DATA),
		friends: filter(DATA),
		incomingRelationships: filter(DATA),
		marks: filter(DATA),
		matchableElements: filter(DATA),
		outgoingRelationships: filter(DATA),
		pending: filter(DATA),
		questions: filter(DATA),
		refused: filter(DATA),
		relationships: filter(DATA),
		selectableElements: filter(DATA),
		sentences: filter(DATA),
		sets: filter(DATA),
		step: filter(CONTENT),
		steps: filter(DATA),
		subElements: filter(DATA),
		thresholds: filter(DATA),
	}
	const get = (object, property, filters) => {
		return (filters[property] || (x => x))(object)
	}
	for (let key in object) {
		object[key] = get(object[key], key, captureFilters)
		if (
			isPlainObject(object[key])
		) {
			object[key] = purify(object[key])
		} else if (
			Array.isArray(object[key])
		) {
			object[key] = object[key].map(x => (isPlainObject(x) ? purify(x) : x))
		}
	}
	if (object.__typename) delete object.__typename
	return object
}

/**
 * Custom array manipulation functions to increase performance
 * @type {{pop: (function(*): *), lastIndexOf: (function(*, *): *), unshift: stackArray.unshift, spliceOne: stackArray.spliceOne, indexOf: stackArray.indexOf, push: (function(*, *): *)}}
 */
export const stackArray = {
	push: (array, value) => array[array.length] = value,
	pop: array => array[array.length--],
	unshift: (array, value) => {
		let len = array.length
		while (len) {
			array[len] = array[len - 1]
			len--
		}
		array[0] = value
	},
	spliceOne: (array, index) => {
		let len = array.length
		if (!len) return
		while (index < len) {
			array[index] = array[++index]
		}
		array.length--
	},
	indexOf: (array, value) => {
		for (let x = 0, len = array.length; x != len; x++) {
			if (array[x] === value) return x
		}
		return -1
	},
	lastIndexOf: (array, value) => {
		let index = array.length
		while (index--) {
			if (array[index] === value) break
		}
		return index
	}
}

/**
 * Returns empty value depending on passed type
 * @param type
 * @returns {*|null}
 */
export const getEmptyValueByType = ({ type }) => {
	return {
		'Integer': 0,
		'Float': 0,
		'String': '',
		'Object': {},
	}[type] || null
	
}

/**
 * Assign deep path property value to object
 * @param object
 * @param path
 * @param value
 * @returns {any}
 * @constructor
 */
export const VueSetDeepProperty = ({ object = {}, path = '', value }) => {
	set(object, path, value)
	return object
	// const pathArray = path.split('.')
	// const property = pathArray.shift()
	// return Object.assign(object, {
	// 	[property]: pathArray.reverse().reduce((a, c) => ({ [c]: a }), value)
	// })
}

/**
 * Transforms a string into a slug
 * @param String
 */
export const slugify = (str) => {
	const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
	const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
	const p = new RegExp(a.split('').join('|'), 'g')
	
	return str.toLowerCase()
		.replace(/\s+/g, '')
		.replace(p, c => b.charAt(a.indexOf(c)))
		.replace(/&/g, '')
		.replace(/[^\w\-]+/g, '')
		.replace(/\-\-+/g, '')
		.replace(/^-+/, '')
		.replace(/-+$/, '')
}

/**
 * Returns the array's correct index when receiving negative or positive index.
 * Usage : When you want a circular index. Given index do never exceed the boundary, but go to the opposite end.
 * @param negativeOrPositiveIndex
 * @param arrayLength
 * @returns {number}
 */
export const getCircularIndex = (negativeOrPositiveIndex, arrayLength) => (arrayLength + (negativeOrPositiveIndex % arrayLength)) % arrayLength

/**
 * Generate Unique Random ID of approximately 20 characters
 * @returns {string}
 */
export const getRandomID = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

/**
 * Returns passed base64Data's content type
 * @param base64Data
 * @returns {void | any | string}
 */
export const getBase64ContentType = base64Data => base64Data.match(/:(.*);/).pop() || ''

/**
 * Returns passed base64Data's data
 * @param base64Data
 * @returns {void | any}
 */
export const getBase64Data = base64Data => base64Data.split(',').pop() || base64Data
/**
 * Returns Blob from base64
 * @param base64Data
 * @param filename
 * @returns {File|null}
 */
export const base64toFile = base64Data => {
	const type = getBase64ContentType(base64Data)
	const data = getBase64Data(base64Data)
	if (!data) return null
	const byteCharacters = atob(data)
	let byteCharactersLength = byteCharacters.length
	const u8array = new Uint8Array(byteCharactersLength)
	while (byteCharactersLength) {
		u8array[byteCharactersLength] = byteCharacters.charCodeAt(byteCharactersLength - 1)
		byteCharactersLength -= 1
	}
	const filename = 'imageInHtml'
	return new File([u8array], filename, { type })
}

/**
 * Returns Blob from Base 64
 * @param base64Data
 * @param sliceSize
 * @returns {Blob}
 */
export const base64toBlob = (base64Data, sliceSize = 1024) => {
	const type = getBase64ContentType(base64Data) || ''
	const data = getBase64Data(base64Data)
	let byteCharacters = atob(data)
	let byteArrays = []
	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		let slice = byteCharacters.slice(offset, offset + sliceSize)
		let byteNumbers = new Array(slice.length)
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i)
		}
		let byteArray = new Uint8Array(byteNumbers)
		byteArrays.push(byteArray)
	}
	return new Blob(byteArrays, {type})
}

/**
 * Returns true/false if x is an even number
 * @param x
 * @returns {boolean}
 */
export const isEven = x => Number.isInteger(x) ? x % 2 === 0 : x

/**
 * Returns true/false if x is an odd number
 * @param x
 * @returns {boolean}
 */
export const isOdd = x => Number.isInteger(x) ? x % 2 === 1 : x

/**
 * Returns array of array's values, positioned at rest indexes
 * @param array
 * @param rest
 * @returns {*}
 */
export const valuesAt = (array, ...rest) => Array.isArray(array) ? rest.reduce((a, c) => [...a, array[c]], []) : array

/**
 * Returns new array with elements inserted in the clone of the passed array at index position
 * Values can be either series of arguments or an array
 * @param array
 * @param index
 * @param rest
 * @returns {*[]}
 */
export const insertAt = (array, index = 0, ...rest) => {
	if (!Array.isArray(array)) return array
	let clone = [...array]
	clone.splice(index, 0, ...rest.flat())
	return clone
}

/**
 * Returns last n elements of array
 * @param array
 * @param howMany
 * @returns {*[]}
 */
export const arrayLast = (array = [], n = 1) => Array.isArray(array) ? [...array].slice(array.length - n) : array

/**
 * Returns first n elements of rray
 * @param array
 * @param n
 * @returns {*[]}
 */
export const arrayFirst = (array = [], n = 1) => Array.isArray(array) ? [...array].slice(0, n) : array

/**
 * Returns sorted array of object, based on order for specific property
 * @param array
 * @param order
 * @param property
 * @returns {*[]}
 */
export const sortArrayWithOrdersOnProperty = (array, order = null, property = null) => {
	if (!array || !order || !property) return array
	const object = order.reduce((a, c) => Object.assign(a, { [c]: array.filter(x => x[property] === c) }), [])
	object.rest = array.filter(x => !order.includes(x[property]))
	return Object.keys(object).reduce((a, c) => [...a, ...object[c]], [])
}

/**
 * Returns next if previous is null, otherwise returns previous
 * @param previous
 * @param next
 * @returns {*}
 */
export const unNull = (previous, next) => previous === null ? next : previous

/**
 * Shuffle's an array
 * @param array
 * @returns {*}
 */
export const shuffle = array => Array.isArray(array)
	? [...array].sort(() => Math.random() - 0.5)
	: []

/**
 * Returns capitalized alphabet, by default, or any array of characters from startLetter to EndLetter inclusively
 * @param startLetter
 * @param endLetter
 * @returns {string[]}
 */
export const getArrayOfCharCodes = (startLetter = 'A', endLetter = 'Z') =>
	Array(endLetter.charCodeAt(0) - startLetter.charCodeAt(0) + 1)
		.fill(startLetter.charCodeAt(0))
		.map((x, index) => x + index)
		.map(x => String.fromCharCode(x))

/** debounce
 * @param func
 * @param wait
 * @param immediate
 * @returns {function(...[*]=)}
 */
export const debounce = function(func, wait, immediate) {
	let timeout
	return function() {
		let context = this, args = arguments
		const later = function() {
			timeout = null
			if (!immediate) func.apply(context, args)
		}
		const callNow = immediate && !timeout
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
		if (callNow) func.apply(context, args)
	}
}

/**
 * returns a composed object from DOM Ids + functions to get data on each id
 * @param object
 * @returns {{} & {[p: string]: *}}
 */
export const DOMValuesWithFunctionsByIds = object => Object.entries(object)
	.reduce((a, c) => Object.assign(a, {
		[c[0]]: c[1](document.getElementById(c[0]))
	}), {})


/**
 * Check if all properties have values
 * @param object
 * @returns {boolean}
 */
export const isAllPropertyHaveValue = object => Object.entries(object)
	.reduce((a, c) => {
		const value = c[1]
		if (!isBoolean(value)) return !!value
		return a
	}, true)

/**
 * Returns an array of objects {x, y} around a circle from centerPoints, with radius & angle between each point
 * It disposes the points proportionally to the left and the right parts, starting from the top
 * @param angle
 * @param radius
 * @param nbPoints
 * @param x
 * @param y
 */
export const fanDispose = ({ angle, radius, nbPoints, centerPoints: { x, y } }) => {
	let workingAngle = 180 - ((nbPoints - 1) % 2 * (angle / 2)) - parseInt((nbPoints - 1) / 2) * angle
	let points = []
	let counter = nbPoints
	while (counter) {
		const radian = Math.PI / 180 * workingAngle
		points.push({
			x: parseInt(radius * Math.sin(radian) + x),
			y: parseInt(radius * -Math.cos(radian) + y)
		})
		workingAngle += angle
		counter -= 1
	}
}

export const removeNonLetters = x => x.toLowerCase().split(/[^a-z]/).join(' ')

export const upperCaseAllWords = x =>
	x.toLowerCase().replace(/\b[a-z](?=[a-z]{2})/g, letter =>letter.toUpperCase())

export const isNoValue = x => {
	if (typeof x === 'undefined') return true
	if (x === null) return true
	return false
}

/**
 * set a debounce closure that uses JS setTimeout that resets delay on every subsequent call
 *
 * first, assign an object containing the closure with the time delay:
 * let x = renewDenounce(1000)
 *
 * then, every time the function is called, the timeout resets:
 * x(() => { ...something })
 *
 * to clear the timeout, simply call the function:
 * x(null)
 *
 * @param time
 * @param timeout
 * @returns {function(...[*]=)}
 */
export const resetDebounce = (time, timeout = null) => func => {
	clearTimeout(timeout)
	if (func) timeout = setTimeout(func, time)
}

export const veeValidation = async ({ version, array }) => {
	switch (version) {
	default: // version 2
		return (
			await Promise.all(array.reduce((a, c) => [...a, c.validate()], []))
		).reduce((a, c) => c === false ? false : a, true)
	}
}

/**
 * returns true if object is an Object
 * @param object
 * @returns {boolean}
 */
export const isObject = object => lodashIsObject(object)

/**
 * returns true if string is a String
 * @param object
 * @returns {boolean}
 */
export const isString = string => string === String(string)

/**
 * returns an object with properties representing the value of the first "property" found
 * it traverse all elements and embedded objects, seeking the first property that match the property argument
 * when found and in String format, the value of the object's property is kept to represent the name of the group
 * each group property will be an array of all elements that match the same group property name
 * ex:
 * const a = [ { scenario: 'first', y: 0 }, { scenario: 'second', x: 1 }, { scenario: 'second', z: 2 } ]
 * const result = deepPropertyGrouping(a)
 * console.log(result)
 * {
 *   first: [ { scenario: 'first', y: 0 } ],
 *   second: [ { scenario: 'second', x: 1 }, { scenario: 'second', z: 2 } ]
 * }
 * @param object
 * @param property
 * @returns {string|*}
 */
export const deepPropertyGrouping = ({ object, property, others = 'others' }) => {
	const objectIteration = object =>
		Object.keys(object)
			.reduce((a, key) => a
				? a
				: key === property && isString(object[key])
					? object[key]
					: isObject(object[key])
						? deepPropertyGrouping({ object: object[key], property })
						: a
			, null)
	if (Array.isArray(object)) {
		return object
			.reduce((a, element) => {
				const found = isObject(element)
					? objectIteration(element)
					: others
				a[found] = [ ...(a[found] || []), element ]
				return a
			}, {})
	} else if (isObject(object)) {
		return objectIteration(object)
	}
	return object
}

/**
 * returns the object's property associated with the passed value
 * @param object
 * @param value
 * @returns {[]}
 */
export const getAllPropertiesByValue = (object, value) => {
	let properties = []
	for (const property in object) {
		if (object[property] === value) properties = [ ...properties, property ]
	}
	return properties
}

/**
 * returns a new array with all values matching value removed
 * @param array
 * @param value
 * @returns {*}
 */
export const removeAllValuesEqualTo = (array, values) =>
	array.reduce((a, c) => !values.includes(c) ? c : a, [])

/**
 * returns new str with all characters escaped
 * @param str
 * @returns {*}
 */
export const escapeAllCharacters = str => str.split('').reduce((a, c) => `${a}\\${c}`, '')

/**
 * returns newText that represent text with all {{ variable_name }} replaced with this[variable_name]
 * NEEDS to be called with .call(this, ...) to move context to the function
 * @param text
 * @param start
 * @param end
 * @returns {string}
 */
export const applyValuesOnString = function({ text, start = '{{', end = '}}' }) {
	let newText = `${text}`
	let _start = escapeAllCharacters(start)
	let _end = escapeAllCharacters(end)
	let regex = new RegExp(`${_start}.+?${_end}`, 'g')
	const variables = (text.match(regex) || [])
		.map(x => x.replace('{{', '').replace('}}', '').trim())
	for (const variable of variables) {
		const value = this[variable]
		const type = typeof value
		if (value && ['string', 'number'].includes(type)) {
			regex = new RegExp(`${_start}.*${variable}.*${_end}`, 'g')
			newText = newText.replace(regex, value)
		}
	}
	return newText
}

/**
 * Returns array of all separated words AND spaces of a given text
 * html tags (from start to end) are considered, for now, as words
 * this means that glossary words embedded in html tags wont be treated
 * @param text
 * @returns {[]}
 */
export const glossarise = text => {
	const array = text.split('')
	const len = array.length
	let newArray = []
	let word = ''
	let spaces = ''
	let html = ''
	for (let i = 0; i < len; i += 1) {
		const letter = array[i]
		if (letter === ' ') {
			if (html) html += letter
			else spaces += letter
			if (word) {
				newArray.push(word)
				word = ''
			}
		} else {
			if (letter === '<') {
				html += letter
				if (spaces || word) newArray.push(word || spaces)
				spaces = word = ''
			} else if (letter === '>') {
				html += letter
				newArray.push(html)
				html = ''
			} else {
				if (html) html += letter
				else word += letter
			}
			if (spaces) newArray.push(spaces)
			spaces = ''
		}
	}
	newArray.push(html + spaces + word)
	html = ''
	let finalArray = []
	for (word of newArray) {
		if (word.startsWith('<')) {
			html += word
			if (word.startsWith('</') || word.endsWith('/>')) {
				finalArray.push(html)
				html = ''
			}
		}
		else if (html) html += word
		else finalArray.push(word)
	}
	return finalArray
}

/**
 * returns all array elements that starts with str
 * @param array
 * @param str
 * @returns {*}
 */
export const allBeginsWith = (array = [], str) => (array || []).
	reduce((a, c) => c.startsWith(str) ? [ ...a, c ] : a, [])

/**
 * removes beginning of string including 'until' search string, trimmed
 * @param string
 * @param until
 * @returns {string}
 */
export const removeStart = (string, until) => string
	.substring(string.indexOf(until) + 1, string.length)
	.trim()

/**
 * returns string with first letter uppercase
 * @param string
 * @returns {*}
 */
export const capFirst = string => string.replace(/^\w/, c => c.toUpperCase())

export const convertIsoDatesInDocument = object => 1

export const JSONFormat = text => text
	.replace(/[\n\r]+/g, '')
	.replace(new RegExp('[\"\']([-_a-zA-Z0-9]+)[\"\']:','gi'), '"$1":')
	.replace(/,}/, '}')
	.replace(/:'/, ':"')
	.replace(/',/, '",')
	.replace(/'}/, '"}')

export const prettifyJSON = text => JSON.stringify(JSON.parse(JSONFormat(text)), undefined, 4)

export const copyToClipboard = ({ text }) => {
	navigator.clipboard.writeText(text)
	store.commit('toolbarMessage', 'ID Copied')
}

export const capitalizeCamel = x => capFirst(camelCase(x))

/**
 * returns the first part of contentType as 'general' and the second as 'specific'
 * @param contentType
 */
export const mediaType = contentType => {
	const [ , general, specific ] = contentType.match(/(\w*)\/(\w*)/)
	return {
		general,
		specific
	}
}

export const splitPopCamel = (text = '', separator) =>
	camelCase(
		text
			.split(separator)
			.pop()
	)

export const splitPopLowerCase = (text = '', separator) =>
	text
		.split(separator)
		.pop()
		.toLowerCase()

export const compose =
	(...fns) =>
		x =>
			fns.reduceRight((v, f) => f(v), x)

export const pipe =
	(...fns) =>
		x =>
			fns.reduce((v, f) => f(v), x)
