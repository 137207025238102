import {fieldDefinition} from '@/JS/constants/constants.js'
import isPlainObject from 'is-plain-object'
import {getRandomID, getValueOnPath, isObject, purify} from '@/JS/utils'
import clientMedia from '@/apollo/queries/CLIENT_MEDIA.gql'
import {sendQuery} from '@/apollo/requeteApollo'
import {fetchMore} from '@/apollo/apolloShare'
import * as metaByType from '@/apollo/queries/META_BY_TYPE.gql'
import { isEmpty, isNil } from 'lodash'
import * as executableInfo from "@/apollo/queries/EXECUTABLE_INFO.gql"
import * as clientLootItemIndex from '@/apollo/queries/CLIENT_LOOT_ITEM_INDEX.gql'
import * as clientModuleIndex from '@/apollo/queries/CLIENT_MODULE_INDEX.gql'

/**
 * IS the passed object correspond to a field definition, as mentioned in constants.js fieldDefinition
 * Especially designed for recursion inside a schema structure
 * Returns true if there is, at least, the same number of properties that are identical to the fieldDefinition constant
 * @param object
 * @returns {*}
 */
export const isField = object => Object.keys(object || {})
	.reduce((a, key) => (fieldDefinition.includes(key) ? a + 1 : a), 0) === fieldDefinition.length

const valueConversions = (value = null, schema) => {
	const { type } = schema
	switch(type.toLowerCase()) {
	case 'date':
		return value
			? new Date(value)
				.toISOString()
				.substring(0, 10)
			: value
	default:
		if (isPlainObject(value) && value.hasOwnProperty('value')) return value.value
	}
	return value
}

/*const setValueOnSchemaFrom0 = (data = {}, schema = {}) => {
	return Object.assign({}, schema, Object.entries(schema)
		.reduce((a, [ key, v ]) => {
			const { type } = v
			if (!type) {
				if (data) {
					Object.assign(a, {
						[key]: setValueOnSchemaFrom(data[key], v),
					})
				}
			} else {
				if (data && schema) {
					Object.assign(a, {
						[key]: Object.assign({}, schema[key], {
							value: valueConversions(data[key], schema[key]) ?? null
						})
					})
				}
			}
			return a
		}, {})
	)
}*/

const setValueOnSchemaFrom = (data = {}, schema = {}) => {
	const properties = [
		'description',
		'id',
		'needed',
		'type',
		'validation',
	]
	const recursive = (data = {}, schema = {}) => {
		return Object.entries(schema)
			.reduce((a, [ property, value ]) => {
				const isField =
					properties
						.reduce((count, property) =>
							value.hasOwnProperty(property)
								? count + 1
								: count
						, 0) === properties.length
				if (isField) {
					Object.assign(a, {
						[property]: Object.assign({}, schema[property], {
							value: valueConversions(data[property], schema[property]) ?? null
						})
					})
				} else {
					Object.assign(a, {
						[property]: recursive(data[property], schema[property])
					})
				}
				return a
			}, {})
	}
	return recursive(data, schema)
}

export const createSchemaForTranslations = (data = {}, schema = {}, { acceptedLanguages = [] } = {}) => {
	if (isEmpty(data) && isEmpty(schema)) return {}
	return acceptedLanguages
		.reduce((a, language) =>
			Object.assign(a, {
				[language]: setValueOnSchemaFrom(data[language] || {}, schema)
			})
		, {})
}

/**
 * Inject values from data inside schema to serve AutoForm
 * ATTENTION : isField() determine if the data correspond to a strict schema structure
 * please see fieldDefinition composition!
 * @param object
 * @returns {{} & {[p: string]: any}}
 */
export const injectValuesInSchema = (data = {}, schema) => Object.keys(schema)
	.reduce((a, key) => {
		const field = schema[key]
		if (
			isPlainObject(field)
		&& !isField(field)
		) {
			return Object.assign(a, {
				[key]: injectValuesInSchema(data[key], field)
			})
		}
		return Object.assign(a, {
			[key]: Object.assign(schema[key], {
				nom: key,
				value: ((data, field) => {
					if (
						/*
						returns FALSE for Boolean's default value
						 */
						field.type.toLowerCase() === 'boolean'
						&& data[key] === undefined
					) {
						return false
					}
					return data[key] || null
				})(data, field)
			})
		})
	}, {})

export const loading = function(boolean, message) {
	this.detailsLoading = boolean
	if (message) this.$store.commit('toolbarMessage', message)
}

export const getStackMedia = async function({ tagName, id, width }) {
	const query = clientMedia
	const variables = {	id }
	const { url: src, contentType } = purify(await sendQuery.call(this.$apolloProvider.defaultClient, {query, variables}))
	if (tagName === 'pdf') return `<a href='${src}'>[PDF]</a>`
	return `<img class='image-blot' data-content-type='${contentType}' id='${getRandomID()}' src='${src}' width='${width}' data-custom='id-${id}' />`
}

export const recomposeStackTag = async function({ tag, getMediaFunction }) {
	if (!tag || !getMediaFunction) return tag
	const array = tag.split(' ')
	const tagName = array.shift()
	const properties = array.reduce((a, c) => {
		const [ property, value ] = c.split(/-(.+)/)
		return Object.assign(a, {
			[property]: value
		})
	}, {})
	switch (tagName) {
	case 'img':
	case 'pdf':
		const { id, width } = properties
		return await getMediaFunction.call(this, { tagName, id, width })
	default:
		return await(await new Promise((resolve) => resolve('')).then(x => x))
	}
}
export const recomposeStackHtml = async function({ html, getMediaFunction = getStackMedia }) {
	if (!html) return html
	let tmp = String(html)
	let newHtml = ''
	while (tmp) {
		let len = tmp.length,
			newTag = '',
			prefix = '',
			suffix = '',
			tag = '',
			tagEnd = 0,
			tagStart = tmp.indexOf('[* ')
		if (tagStart > -1) {
			tagEnd = tmp.indexOf(' *]')
			prefix = tmp.substring(0, tagStart)
			tagStart += 3
			tag = tmp.substring(tagStart, tagEnd)
			tagEnd += 3
		} else {
			prefix = tmp.substring(0, len)
			tagEnd = len
		}
		if (tag) newTag = await recomposeStackTag.call(this, { tag, getMediaFunction })
		newHtml = `${newHtml}${prefix}${newTag}`
		tmp = tmp.substring(tagEnd, len)
		if (!tmp) newHtml =  `${newHtml}${suffix}`
	}
	return newHtml
}

export const createObjectWithProperties = ({ array, properties }) => array
	.reduce((a, c) => Object.assign(a, {
		[c]: properties
	}), {})

export const getPaginationObject = array => createObjectWithProperties({ array, properties: {
	length: 0,
	limit: 0,
	page: 0
}})

export const getActualPage = (pagination, increment = 0) => {
	let { count, page, limit } = pagination
	if (!increment) return page
	count += increment
	if (
		count % limit === 0
		&& page > Math.floor(count / limit)
	) {
		return Math.max(page - 1, 1)
	}
	const fullPages = Math.floor(count / limit)
	const rest = count % limit
	if (rest) return fullPages + 1
	return page
}

export const setSettingsVariables = function({ mutation, namespace, variables = {}}) {
	this.$store.commit(`${namespace}/setProperty`, { property: 'settingsVariables', value: { mutation, variables } })
}

export const pageChange = function({ page, query }) {
	fetchMore({
		query: this.$apollo.queries[query],
		page
	})
}

export const getMetaByType = async function({ elementType }) {
	return await sendQuery.call(this.$apolloProvider.defaultClient, {
		query: metaByType,
		variables: { elementType }
	})
}

export const setMetaByType = async function({ elementType, store, extra = {} }) {
	if (!elementType || !store) return
	const data = await getMetaByType.call(this, { elementType })
	Object.assign(data, extra)
	await this.$store.dispatch(`${store}/setModelMeta`, { data })
	await this.$store.dispatch(`${store}/setProperty`, {property: 'elementType', value: elementType })
}

export const setStoreProperties = function({ properties, store }) {
	Object.entries(properties)
		.forEach(x => this.$store.dispatch(`${store}/setProperty`, {property: x[0], value: x[1]}))
}

export const isMedia = ({ elementType }) => /::Media.*$/.test(String(elementType))

export const getValidation = function(data) {
	const {needed, type, validation} = data
	let result = ''
	if (!validation) return result
	const { type: validationType = null, params = {} } = validation
	const listeValidations = Object.keys(
		this.$validator.dictionary.container.en.messages
	).reduce((a, key) => key.substr(0, 1) !== '_' ? [...a, key] : a, [])
	if (needed) result = 'required'
	result = type.toLowerCase() !== 'editor' ? `${result}|${type}` : result
	if (
		validationType
		&& listeValidations.includes(validationType)
	) {
		let validation = validationType
		if (!isEmpty(params)) validation += `:${Object.entries(params || {}).reduce((a, c) => a ? `${a},${c[1]}` : `${c[1]}`, '')}`
		result = `${result}|${validation}`
	}
	return result
}

export const convertToMultipleLanguageObject = ({
	                                                object,
	                                                fieldName,
	                                                description,
	                                                needed,
	                                                type,
	                                                validation
}) => Object.keys(object)
	.reduce((a, key) => Object.assign(a, {
		[key]: {
			[fieldName] : {
				description,
				id: 0,
				needed,
				type,
				validation,
				value: object[key]
			}
		}
	}), {})

export const getValuesFromTranslations = object =>
	Object.keys(object)
		.reduce((a, key) => {
			const oKey = object[key]
			return !isField(oKey) && isPlainObject(oKey)
				? Object.assign(a, {
					[key]: getValuesFromTranslations(oKey)
				})
				: Object.assign(a, {
					[key]: oKey.value === null
						? oKey.type === 'Boolean'
							? false
							: ''
						: oKey.value
				})
		}, {})

export const keepTranslationsData = (languages, data = {}, schema = {}) => {
	if (isEmpty(schema)) return {}
	
	const properties = [
		'description',
		'id',
		'needed',
		'type',
	]
	const recursive = (data = {}, schema = {}) => {
		return Object.entries(schema)
			.reduce((a, [ property, value ]) => {
				const isField =
					properties
						.reduce((count, key) =>
							value.hasOwnProperty(key)
								? count + 1
								: count
						, 0) === properties.length
				
				if (isField) {
					Object.assign(a, {
						[property]: Object.assign({}, schema[property], {
							value: data[property]?.value ?? null,
						})
					})
				} else {
					Object.assign(a, {}, {
						[property]: recursive(data[property], value),
					})
				}
				return a
			}, {})
	}

	const object = languages
		.reduce((a, language) => Object.assign(a, {[language]: schema}), {})
		
	return Object.keys(object)
		.reduce((a, language) => {
			return Object.assign(a, {
				[language]: recursive(data[language], schema)
			})
		}, {})
	
	
	// return Object.keys(object)
	// 	.reduce((a, language) => {
	// 		debugger
	// 		return Object.assign(a, {
	// 			[language]: Object.keys(object[language])
	// 				.reduce((a, field) => {
	// 					debugger
	// 					const result = Object.assign(a, recursive(data[language], schema))
	// 					debugger
	// 					return result
	// 				}, {})
	// 		})
	// 	}, {})
}

export const keepTranslationsField = (languages, field = {} ) => {
	return languages
		.reduce((a, language) => {
			return Object.assign(a, {
				[language]: field[language] || null
			})
		}, {})
}

export const transformCmeCode = x => {
	const { document } = x
	if (!document) return {}
	const { cme_code } = document
	return cme_code
		? {
			text: x.name,
			value: cme_code
		}
		: {}
}

export const getExecutableInfo = async function({ elementType, namespace }) {
	const elementTypes = {
		'Client::LootItem': clientLootItemIndex,
		'Client::Module': clientModuleIndex,
	}
	let query = executableInfo
	const variables = { elementType }
	let result = purify(await sendQuery.call(this.$apolloProvider.defaultClient, { query, variables }))
	if (!result || !Array.isArray(result)) return
	const queries = result
		.reduce((a, { name, params: { layout: { options } = {} } = {} }) =>
			name && options && Array.isArray(options)
				? Object.assign(a, options[0]
					.reduce((a, option) =>
						isObject(option)
							? Object.keys(option)
								.reduce((a, key) => {
									const { element_type, field:path, label } = option[key]
									return Object.assign(a, {
										[element_type]: {
											label,
											query: elementTypes[element_type] || null,
											path
										}
									})
								}, {})
							: a, {}))
				: a, {})
	const _queries = {}
	for (const key of Object.keys(queries)) {
		let { query:_query, path } = queries[key]
		if (_query && path) {
			const { data } = purify(await sendQuery.call(this.$apolloProvider.defaultClient, {query:_query, variables: {}}))
			if (data && Array.isArray(data)) {
				Object.assign(_queries, {
					[key]: data
						.reduce((a, { id, document, slug:option }) => {
							if (id && option && document) {
								const [ , ...rest ] = path.split('.')
								if (rest.length) {
									const exist = getValueOnPath(document, rest.join('.'))
									if (exist) {
										return [ ...a, {
											option,
											id
										}]
									}
								}
							}
							return a
						}, [])
				})
			}
		}
	}
	this.$store.commit(`${namespace}/setProperty`, { property: 'executableInfo', value: result })
	this.$store.commit(`${namespace}/setProperty`, { property: 'executableQueries', value: _queries })
}
