import '@babel/polyfill'
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import App from './App.vue'
import router from './router'
import store from './store'
import {createProvider} from './vue-apollo'
import VeeValidate from 'vee-validate'
import vuexI18n from 'vuex-i18n'
import {messages as FR} from './JS/constants/messagesFR'
import {messages as EN} from './JS/constants/messagesEN'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import '@mdi/font/css/materialdesignicons.css'
import { extendVeeValidate } from './JS/Validators'
import {REFRESH_TOKEN} from "@/JS/refreshToken"
import {sendMutation} from "@/apollo/requeteApollo"

const apolloProvider = createProvider()
const vuetifyOptions = {}
Vue.config.productionTip = false
Vue.prototype.eventBus = new Vue()

Vue.use(vuexI18n.plugin, store)
Vue.i18n.add('en', EN)
Vue.i18n.add('fr', FR)

Vue.use(VueApollo)
Vue.use(VeeValidate)
Vue.use(Vuetify)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

setInterval(async function() {
	if (!REFRESH_TOKEN.getData()) return
	const {accessToken, refreshToken} = await sendMutation.call(
		apolloProvider.defaultClient,
		{
			mutation: require('@/apollo/mutations/REFRESH_ADMIN_USER_TOKEN.gql')
		}
	)
	if (accessToken && refreshToken) {
		await store.dispatch('loginSuccessful', {
			accessToken,
			refreshToken,
		})
	} else {
		await store.dispatch('removeAccessTokens')
	}
}, 28 * 60 * 1000)

router.beforeEach(async (to, from, next) => {
	if (
		REFRESH_TOKEN.getData()
		&& !store.getters.getAccessToken
	) {
		store.commit('setIsRefreshToken', true)
		const { accessToken, refreshToken } = await sendMutation.call(apolloProvider.defaultClient, {
			mutation: require("@/apollo/mutations/REFRESH_ADMIN_USER_TOKEN.gql")
		})
		if (accessToken && refreshToken) {
			await store.dispatch('loginSuccessful', { accessToken, refreshToken })
			store.commit('setIsRefreshToken', false)
		}
	}
	
	const publicPages = ["login", "register"]
	const authRequired = !publicPages.includes(to.name)
	const loggedIn = !!store.getters.getAccessToken
	
	if (authRequired && !loggedIn) {
		return next("login")
	}
	next()
})

const app = new Vue({
	icons: {
		iconfont: 'mdi'
	},
	router,
	store,
	apolloProvider,
	vuetify: new Vuetify(vuetifyOptions),
	render: h => h(App),
	created() {
		extendVeeValidate(this.$validator)
	}
})
	.$mount('#app')
	.$store.dispatch('setLanguage')

const VCard = Vue.component('VCard')
VCard.options.props.ripple.default = false
