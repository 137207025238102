// import { onObjectKeysApply } from './utils'
/*
	TODO Remplacer le localStorage par une fonctionnalité sécuritaire
 */
export const factory = definition => ({
	definition,
	getDefinition() {
		return this.definition
	},
	getData() {
		return window.localStorage.getItem(this.getDefinition())
	},
	setData({data}) {
		if (window.localStorage) {
			window.localStorage.setItem(this.getDefinition(), data)
		}
	},
	clearData() {
		if (window.localStorage) {
			window.localStorage.removeItem(this.getDefinition())
		}
	},
	isData() {
		return !!this.getData()
	}
})
