var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "div",
        { staticClass: "aaa" },
        [
          _c(
            _vm.layout,
            { tag: "component" },
            [
              _c("router-view", {
                attrs: { layout: _vm.layout },
                on: {
                  "update:layout": function ($event) {
                    _vm.layout = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("StackAlert"),
      _c("v-snackbar", { attrs: { value: _vm.message, color: "success" } }, [
        _vm._v(" " + _vm._s(_vm.message) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }