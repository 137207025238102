export const messages = {
	save: 'save',
	Dropzone: {
		title: 'Drop files here or click to select',
		removeFile: 'Remove file',
		error: {
			maxfilesexceeded: `One media file only is allowed here.
				Please remove the previous file to insert this one or click [ SAVE ]
				to enter the next media.`,
		}
	},
	Schema: {
		Errors: {
			nonEmptyBranch: `The branch must be empty to delete it.`,
			emptyBranch: `Empty branches are not accepted in schemas.`,
			sameBranchName: `Branches must have different names.`
		}
	},
	Media: {
		Errors: {
			selectMedia: `Please drag a file in the zone below or click on it to select a file from your computer.`
		}
	},
	Content: {
		Errors: {
			Empty: `Please enter some content.`
		}
	},
	Answers: {
		Errors: {
			invalidScore: 'Please enter a value between 0 and 1 inclusively.'
		}
	},
	Dialog: {
		Delete: 'The element cannot be recovered!'
	},
	Yes: 'Yes',
	No: 'No'
}
