import { isBetweenEqual } from "./utils"
import { isBoolean, isInteger } from 'lodash'
import {isJSONObject} from "./utils"

const identifier = ({ field, min, max }) => `The ${field} field can only be lowercase alphabetic or numeric, separated by hyphens or single dot, and be ${min} to ${max} of length.`

export const extendVeeValidate = validator => {
	validator.extend('alpha', {
		getMessage: field => `The ${field} is not a valid alpha string`,
		validate: value => /^[A-Za-zÀ-ÖØ-öø-ſ]+(?:[-'\s][A-Za-zÀ-ÖØ-öø-ſ]+)*$/.test(value)
	})
	validator.extend('JSON', {
		getMessage: field => `The ${field} is not a JSON object`,
		validate: value => isJSONObject(value)
	})
	validator.extend('String', {
		getMessage: field => `The ${field} field has invalid value (null, undefined) for a String`,
		validate: value => ![null, undefined].includes(value)
	})
	validator.extend('Integer', {
		getMessage: field => `The ${field} field is not an Integer`,
		validate: value => isInteger(Number(value))
	})
	validator.extend('Float', {
		getMessage: field => `The ${field} field is not a Float`,
		validate: value => !isNaN(value)
	})
	validator.extend('Boolean', {
		getMessage: field => `The ${field} field is not a Boolean`,
		validate: value => isBoolean(value)
	})
	validator.extend('Array', {
		getMessage: field => `The ${field} field is not an Array`,
		validate: value => Array.isArray(value)
	})
	validator.extend('Hash', {
		getMessage: field => `The ${field} field is not a Hash`,
		validate: value => true
	})
	validator.extend('Editor', {
		getMessage: field => `The ${field} field is not an Editor`,
		validate: value => {
			return true
		}
	})
	validator.extend('url', {
		getMessage: (field) => `The ${field} field is not a valid URL.`,
		validate: (value) => /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(value)
	})
	validator.extend('externalURL', {
		getMessage: (field) => `The ${field} field is not a valid external URL.`,
		validate: (value) => /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(value)
	})
	validator.extend('any', {
		getMessage: (field, [ min, max ]) => `The ${field} field can only be ${min} to ${max} of length, and have only one space between words.`,
		validate: (value, [ min, max ]) => {
			const length = value.toString().length
			return isBetweenEqual(length, min, max) && /^([A-Za-z0-9\-\s_]+(\.|\s){0,1})+$/.test(value)
		}
	})
	validator.extend('clientIdentifier', {
		getMessage: (field, [ min, max ]) => identifier({ field, min, max }),
		validate: (value, [ min, max ]) => {
			const length = value.toString().length
			return isBetweenEqual(length, min, max) && /^([a-z0-9\-\s_]+(\.){0,1})+$/.test(value)
		}
	})
	validator.extend('name', {
		getMessage: (field, [ min, max ]) => identifier({ field, min, max }),
		validate: (value, [ min, max ]) => {
			const length = value.toString().length
			return isBetweenEqual(length, min, max) && /^([a-z0-9\-\s_]+(\.){0,1})+$/.test(value)
		}
	})
	validator.extend('slug', {
		getMessage: (field) => `The ${field} field must be an alphanumeric slug name`,
		validate: (value) => /[A-Za-z0-9]/.test(value)
	})
}
