export const messages = {
	save: 'sauver',
	Dropzone: {
		title: 'Déposez votre fichier ici ou cliquez pour le choisir',
		removeFile: 'Enlever le fichier',
		error: {
			maxfilesexceeded: `Un seul fichier média peut être placé ici.
					Veuillez enlever le fichier précédent pour insérer celui-ci ou appuyer sur [ SAUVER ]
					pour entrer le média suivant.`,
		}
	},
	Schema: {
		Errors: {
			nonEmptyBranch: `La branche doit être vide pour la détruire.`,
			emptyBranch: `Les branches vides ne sont pas acceptées dans les schémas.`,
			sameBranchName: `Les branches doivent avoir des noms différents.`
		}
	},
	Media: {
		Errors: {
			selectMedia: `Veuillez déposer un fichier dans la zone ci-dessous ou cliquez dessus pour choisir un fichier de votre ordinateur.`
		}
	},
	Content: {
		Errors: {
			Empty: `Veuillez entrer du contenu.`
		}
	},
	Answers: {
		Errors: {
			invalidScore: 'Veuillez entrer une valeur entre 0 et 1 inclusivement.'
		}
	},
	Dialog: {
		Delete: `L'élément ne peut être récupéré !`
	},
	Yes: 'Oui',
	No: 'Non'
}
