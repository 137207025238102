const capitalize = function (value) {
	if (!value) return ""
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
}

const contentTypeLast = value => (value.match(/.*\/(.*)$/) || []).pop()

const toUpperCase = x => String(x).toUpperCase()

const removeHtmlTags = x => x.replace(/<\/?[^>]+(>|$)/g, '')

const plural = x => x.endsWith('s') ? x : `${x}s`

const singular = x => x.endsWith('s') ? x.slice(0, -1) : x

const camelToWords = x => {
	const result = x.replace( /([A-Z])/g, " $1" ).toLowerCase()
	return `${result.charAt(0).toUpperCase()}${result.slice(1)}`
}

const trimMore = (x, length) => typeof x === 'string'
	? `${x.substring(0, length - 1)}${x.length > length ? '...' : '' }`
	: x

module.exports = {
	filters: {
		capitalize,
		contentTypeLast,
		toUpperCase,
		removeHtmlTags,
		plural,
		singular,
		camelToWords,
		trimMore
	}
}
