import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const client = 'client'
const stack = 'stack'

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/client",
			name: "client",
			component: () => import('@/views/Client/Client'),
			meta: {
				type: client,
				tooltip: 'Client',
				icon: 'domain'
			}
		},
		{
			path: "/group",
			name: "group",
			component: () => import('@/views/Client/Group'),
			meta: {
				type: client,
				tooltip: 'Groupe',
				icon: 'mdi-account-group'
			}
		},
		{
			path: "/module",
			name: "module",
			component: () => import('@/views/Client/Module'),
			meta: {
				type: client,
				tooltip: 'Module',
				icon: 'mdi-group'
			}
		},
		{
			path: "/section",
			name: "section",
			component: () => import('@/views/Client/Section'),
			meta: {
				type: client,
				tooltip: 'Section',
				icon: 'mdi-vector-intersection'
			}
		},
		{
			path: "/game",
			name: "game",
			component: () => import('@/views/Client/Game'),
			meta: {
				type: client,
				tooltip: 'Jeu',
				icon: 'mdi-gamepad-variant'
			}
		},
		{
			path: "/step",
			name: "step",
			component: () => import('@/views/Client/Step'),
			meta: {
				type: client,
				tooltip: 'Étape',
				icon: 'mdi-debug-step-into'
			}
		},
		{
			path: "/text",
			name: "text",
			component: () => import('@/views/Client/TextContent'),
			meta: {
				type: client,
				tooltip: 'Texte',
				icon: 'mdi-format-text'
			}
		},
		{
			path: "/matchingSet",
			name: "matchingSet",
			component: () => import('@/views/Client/MatchingSet'),
			meta: {
				type: client,
				tooltip: 'Matching set',
				icon: 'mdi-set-all'
			}
		},
		{
			path: "/content",
			name: "content",
			component: () => import('@/views/Client/Content'),
			meta: {
				type: client,
				tooltip: 'Contenu',
				icon: 'mdi-monitor-screenshot'
			}
		},
		{
			path: "/question",
			name: "question",
			component: () => import('@/views/Client/Question'),
			meta: {
				type: client,
				tooltip: 'Question',
				icon: 'mdi-comment-question'
			}
		},
		{
			path: "/loot",
			name: "loot",
			component: () => import('@/views/Client/Loot'),
			meta: {
				type: client,
				tooltip: 'Butin',
				icon: 'mdi-treasure-chest'
			}
		},
		{
			path: "/file",
			name: "file",
			component: () => import('@/views/Client/File'),
			meta: {
				type: client,
				tooltip: 'Fichiers',
				icon: 'mdi-file-outline'
			}
		},
		{
			path: "/filter",
			name: "filter",
			component: () => import('@/views/Client/Filtre'),
			meta: {
				type: client,
				tooltip: 'Filtres',
				icon: 'mdi-filter'
			}
		},
		{
			path: "/deck",
			name: "deck",
			component: () => import('@/views/Client/DeckOfCards'),
			meta: {
				type: client,
				tooltip: 'Paquet de cartes',
				icon: 'mdi-cards'
			}
		},
		{
			path: "/scramble",
			name: "scramble",
			component: () => import('@/views/Client/Scramble.vue'),
			meta: {
				type: client,
				tooltip: 'Éléments mêlés',
				icon: 'mdi-emoticon-confused'
			}
		},
		{
			path: "/settings",
			name: "settings",
			component: () => import('@/views/Stack/Settings'),
			meta: {
				type: stack,
				tooltip: 'Paramètres',
				icon: 'mdi-cog'
			}
		},
		{
			path: "/message",
			name: "message",
			component: () => import('@/views/Stack/Message'),
			meta: {
				type: stack,
				tooltip: 'Messages',
				icon: 'mdi-message'
			}
		},
		{
			path: "/user",
			name: "user",
			component: () => import('@/views/Client/User'),
			meta: {
				type: stack,
				tooltip: 'User',
				icon: 'mdi-account'
			}
		},
		{
			path: "/Schema",
			name: "Schema",
			component: () => import('@/views/Stack/Schema'),
			meta: {
				type: stack,
				tooltip: 'Schéma',
				icon: 'mdi-file-tree'
			}
		},
		{
			path: "/",
			name: "home",
			component: () => import('@/views/Home'),
		},
		{
			path: "/register",
			name: "register",
			component: () => import('@/views/SignIn'),
		},
		{
			path: "/login",
			name: "login",
			component: () => import('@/views/LogIn'),
		},
		{
			path: "/logout",
			name: "logout",
			component: () => import('@/views/LogOut'),
		}
	]
})

export default router
