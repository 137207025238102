import Vue from 'vue'
import Vuex from 'vuex'
import { isNumber, uniqBy } from 'lodash'
import {REFRESH_TOKEN} from '@/JS/refreshToken'
import {CLIENT} from '@/JS/client'
import {LANGUAGE} from '@/JS/Language'
import {filters} from '@/JS/UI/Filters'
import {
	VueSetDeepProperty,
	setTimeoutOn
} from '@/JS/utils'
import { cloneDeep } from 'lodash'
import isPlainObject from 'is-plain-object'
import {isField} from '@/JS/StackAdminUtils/utils'

Vue.use(Vuex)

const mapFunctions = (property, functions) => Object.keys(functions)
	.reduce((a, prop) => Object.keys(functions)
		.reduce((a, func) => Object.assign(a, {
			[`${property}${filters.capitalize(func)}`]: functions[func]
		}), {}), {})

const stateAutoForm = () => ({
	id: null,
	criterias: [],
	rewards: [],
	conditions: [],
	tags: [],
	tagsSelected: [],
	slug: '',
	state: 'development',
	Fields: {},
	schemaId: null,
	schema: {},
	meta: {
		modelMeta: {}
	}
})

const resetFieldsValue = (object = {}) => {
	return Object.keys(object || {})
		.reduce((a, key) => {
			const oKey = object[key]
			if (
				!isField(oKey)
				&& isPlainObject(oKey)
			) {
				return Object.assign(a, {
					[key]: resetFieldsValue(oKey)
				})
			}
			return Object.assign(a, {
				[key]: Object.assign(oKey, {
					value: null
				})
			})
		}, {})
}

const autoFormMutations = () => ({
	resetState(state) {
		const { Fields = {} } = state
		Vue.set(state, 'id', null)
		Vue.set(state, 'slug', '')
		Vue.set(state, 'state', 'development')
		Vue.set(state, 'Fields', resetFieldsValue(Fields))
		Vue.set(state, 'tagsSelected', [])
	},
	...mapFunctions('rewards', arrayCRUDMutations('rewards')),
	...mapFunctions('rewards', trash('rewards')),
	...mapFunctions('criterias', arrayCRUDMutations('criterias')),
	...mapFunctions('criterias', trash('criterias')),
	...mapFunctions('conditions', arrayCRUDMutations('conditions')),
	...mapFunctions('conditions', trash('conditions')),
	...mapFunctions('tags', arrayCRUDMutations('tags')),
	...mapFunctions('tagsSelected', arrayCRUDMutations('tagsSelected')),
	setModelMeta(state, { data }) {
		const { meta: { modelMeta } = {}} = state
		if (modelMeta) Vue.set(state.meta, 'modelMeta', data)
	},
	setTags(state, { data }) {
		if (!data) return
		Vue.set(state, 'tags', data)
	},
	setField(state, { field, value = null }) {
		if (!field || !state.Fields) return
		state.Fields[field].value = value
	}
})

const autoFormActions = () => ({
	setModelMeta({commit, getters, func}, { data }) {
		commit('setModelMeta', { data })
	},
	setTags({commit, getters, func}, { data }) {
		commit('setTags', { data })
	}
})

const autoFormGetters = () => ({
	...mapFunctions('fields', objectGetters('Fields')),
	modelMeta(state) {
		return state.meta.modelMeta
	},
	hasReward(state) {
		return state.meta.modelMeta.hasReward
	},
	hasCriteria(state) {
		return state.meta.modelMeta.hasCriteria && state.meta.modelMeta.hasCriteria.length
	}
})

const arrayGetters = context => ({
	getArray(state) {
		return state[context]
	}
})

const objectGetters = context => ({
	getObject(state) {
		return state[context]
	}
})

const arrayCRUDMutations = context => ({
	addElement(state, {value, uniqueBy = null}) {
		state[context].push(cloneDeep(value))
		if (uniqueBy) state[context] = uniqBy(state[context], uniqueBy)
	},
	updateElement(state, {value, index}) {
		state[context].splice(index, 1, value)
	},
	deleteElement(state, {index}) {
		delete state[context][index].active
		state[context].splice(index, 1)
	},
	setArray(state, {data}) {
		Vue.set(state, context, data)
	},
	resetArray(state) {
		Vue.set(state, context, null)
		Vue.set(state, context, [])
	}
})

const arrayActions = () => ({
	getArray({commit, getters, func}) {
		return getters.getArray()
	},
	addElement({commit}, { value }) {
		commit('addElement', { value })
	},
	updateElement({commit}, { value, index }) {
		commit('updateElement', { value, index })
	},
	deleteElement({commit}, { index }) {
		commit('deleteElement', { index })
	},
	setArray({commit}, { data }) {
		commit('setArray', { data })
	},
	resetArray({commit}) {
		commit('resetArray')
	}
})

const objectMutations = () => ({
	setProperty(state, {property, value}) {
		if (property.includes('.')) VueSetDeepProperty({ object: state, path: property, value })
		else Vue.set(state, property, value)
	},
	setProperties(state, properties) {
		Object.assign(state, properties)
	},
	deleteProperty(state, {property}) {
		Vue.set(state, property, null)
	},
})

const objectActions = () => ({
	setProperty({commit}, { property, value }) {
		if (!property) return
		commit('setProperty', { property, value })
	},
	setProperties({commit}, { properties }) {
		if (!properties) return
		commit('setProperties', { properties })
	},
	getProperty({commit, state}, { property }) {
		if (!property) console.log('getProperty() invalid params')
	},
	deleteProperty({commit}, { property }) {
		if (!property) console.log('deleteProperty() invalid params')
		commit('deleteProperty', { property })
	},
})

const trash = context => ({
	activateTrash(state, {index}) {
		if (!state[context]) return
		Vue.set(state, context, state[context]
			.map((x, i) => {
				Vue.set(x, 'active', i === index)
				return x
			}))
	}
})

const store_clients = {
	namespaced: true,
	state: {
		...stateAutoForm(),
	},
	mutations: {
		...autoFormMutations(),
		...objectMutations(),
	},
	actions: {
		...autoFormActions(),
		...objectActions()
	},
	getters: {}
}

const store_steps = {
	namespaced: true,
	state: {
		...stateAutoForm(),
		game: {},
		gameSteps: [],
		origins: [],
		content: [],
		conditions: [],
		rewards: [],
		targets: [],
		active: {
			condition: null,
			reward: null,
			threshold: null
		},
		weighting: 0,
	},
	mutations: {
		...mapFunctions('gameSteps', arrayCRUDMutations('gameSteps')),
		...mapFunctions('origins', arrayCRUDMutations('origins')),
		...mapFunctions('content', arrayCRUDMutations('content')),
		...mapFunctions('conditions', arrayCRUDMutations('conditions')),
		...mapFunctions('rewards', arrayCRUDMutations('rewards')),
		...mapFunctions('targets', arrayCRUDMutations('targets')),
		...mapFunctions('origins', trash('origins')),
		...mapFunctions('conditions', trash('conditions')),
		...mapFunctions('rewards', trash('rewards')),
		...autoFormMutations(),
		...objectMutations(),
		setActive(state, { property = '', index = null }) {
			if (!property) return
			Vue.set(state.active, property, index)
		},
		toggleActive(state, { property = '', index = null }) {
			if (!property) return
			if (state.active[property] === null) {
				Vue.set(state.active, property, index)
				return
			}
			Vue.set(state.active, property, null)
		},
		resetActive(state, { property = ''}) {
			if (!property) return
			Vue.set(state.active, property, null)
		},
		setConditionData(state, { data }) {
			if (!data) return
			const { condition: index } = state.active
			if (!isNumber(index)) return
			const { option_id, option_text, tooltip, params, type } = data
			Vue.set(state.conditions[index], 'btn', {
				option_id,
				option_text,
				tooltip
			})
			Vue.set(state.conditions[index], 'params', params)
			Vue.set(state.conditions[index], 'type', type)
		},
		addConditionTarget(state, { data }) {
			if (!data) return
			const { condition: index } = state.active
			if (!isNumber(index)) return
			Vue.set(state.conditions[index], 'targetId', data)
		},
		updateRewardProperty(state, { property, data }) {
			if (!isNumber(state.active.reward)) return
			Vue.set(state.rewards[state.active.reward], property, data)
		},
		updateThresholdProperty(state, { property, data }) {
			if (!isNumber(state.active.threshold)) return
			Vue.set(state.rewards[state.active.reward].thresholds[state.active.threshold], property, data)
		},
		updateThresholdPropertyByIndex(state, { property, rewardIndex, thresholdIndex, data }) {
			if (!isNumber(rewardIndex) || !isNumber(thresholdIndex)) return
			Vue.set(state.rewards[rewardIndex].thresholds[thresholdIndex], property, data)
		},
		thresholdsAddElement(state, { data }) {
			if (!data) return
			if (!state.rewards[state.active.reward].thresholds) state.rewards[state.active.reward].thresholds = []
			state.rewards[state.active.reward].thresholds.push(data)
			Vue.set(state.active, 'threshold', data)
		},
		thresholdsDeleteElement(state, { index }) {
			if (
				!isNumber(index)
				|| !isNumber(state.active.reward)
				|| !isNumber(state.active.threshold)
			) return
			state.rewards[state.active.reward].thresholds.splice(index, 1)
			Vue.set(state.active, 'threshold', null)
		}
	},
	actions: {
		...autoFormActions(),
		...objectActions()
	},
	getters: {
		...autoFormGetters(),
		actualCondition: state => isNumber(state.active.condition) && state.conditions.length ? state.conditions[state.active.condition] : null,
		actualReward: state => isNumber(state.active.reward) && state.rewards.length ? state.rewards[state.active.reward] : null,
		getAmount: state => {
			const { reward, threshold } = state.active
			if (!isNumber(reward) || !isNumber(threshold)) return 0
			if (!state.rewards.length) return 0
			return state.rewards[reward].thresholds[threshold].amount
		},
		getScore: state => {
			const { reward, threshold } = state.active
			if (!isNumber(reward) || !isNumber(threshold)) return 0
			if (!state.rewards.length) return 0
			return state.rewards[reward].thresholds[threshold].score
		},
	}
}

const store_questions = {
	namespaced: true,
	state: {
		...stateAutoForm(),
		questions: []
	},
	mutations: {
		...arrayCRUDMutations('questions'),
		...objectMutations(),
		...trash('questions')
	},
	actions: {
		...arrayActions(),
	},
	getters: {}
}

const store_contentTexts = {
	namespaced: true,
	state: {
		contentTexts: []
	},
	mutations: {
		...arrayCRUDMutations('contentTexts'),
		...trash('contentTexts')
	},
	actions: {
		...arrayActions(),
	},
	getters: {}
}

const store_textContent = {
	namespaced: true,
	state: {
		specificInfo: {},
		html: '',
		clientMedias: [],
		...stateAutoForm(),
	},
	mutations: {
		...autoFormMutations(),
		...objectMutations(),
		...mapFunctions('clientMedias', arrayCRUDMutations('clientMedias')),
		...mapFunctions('clientMedias', trash('clientMedias')),
		clearSpecific(state) {
			Vue.set(state, 'specificInfo', {})
		},
	},
	actions: {
		...autoFormActions(),
		...objectActions()
	},
	getters: {

	}
}

const store_module = {
	namespaced: true,
	state: {
		...stateAutoForm(),
		parent: [],
		games: [],
		medias: [],
		rewards: [],
		active: {
			reward: null,
			threshold: null
		},
	},
	getters: {
		...autoFormGetters(),
	},
	mutations: {
		...objectMutations(),
		...autoFormMutations(),
		...mapFunctions('medias', arrayCRUDMutations('medias')),
		...mapFunctions('medias', trash('medias')),
		...mapFunctions('games', arrayCRUDMutations('games')),
		...mapFunctions('games', trash('games')),
		...mapFunctions('rewards', arrayCRUDMutations('rewards')),
		...mapFunctions('rewards', trash('rewards')),
		setActive(state, { property = '', index = null }) {
			if (!property) return
			Vue.set(state.active, property, index)
		},
		updateRewardProperty(state, { property, data }) {
			if (!isNumber(state.active.reward)) return
			Vue.set(state.rewards[state.active.reward], property, data)
		},
		updateThresholdProperty(state, { property, data }) {
			if (!isNumber(state.active.threshold)) return
			Vue.set(state.rewards[state.active.reward].thresholds[state.active.threshold], property, data)
		},
		thresholdsAddElement(state, { data }) {
			if (!data) return
			if (!state.rewards[state.active.reward].thresholds) state.rewards[state.active.reward].thresholds = []
			state.rewards[state.active.reward].thresholds.push(data)
			Vue.set(state.active, 'threshold', data)
		},
		thresholdsDeleteElement(state, { index }) {
			if (
				!isNumber(index)
				|| !isNumber(state.active.reward)
				|| !isNumber(state.active.threshold)
			) return
			state.rewards[state.active.reward].thresholds.splice(index, 1)
			Vue.set(state.active, 'threshold', null)
		}
	},
	actions: {
		...autoFormActions(),
		...objectActions(),
	}
}

const store_section = {
	namespaced: true,
	state: {
		specificInfo: {},
		active: {
			criteria: null
		},
		decks: [],
		parent: [],
		children: [],
		clientMedias: [],
		clientGames: [],
		clientFiles: [],
		contentTexts: [],
		criterias: [],
		...stateAutoForm(),
	},
	mutations: {
		...objectMutations(),
		...autoFormMutations(),
		...mapFunctions('parent', trash('parent')),
		...mapFunctions('decks', arrayCRUDMutations('decks')),
		...mapFunctions('decks', trash('decks')),
		...mapFunctions('contentTexts', arrayCRUDMutations('contentTexts')),
		...mapFunctions('contentTexts', trash('contentTexts')),
		...mapFunctions('clientFiles', arrayCRUDMutations('clientFiles')),
		...mapFunctions('clientFiles', trash('clientFiles')),
		...mapFunctions('clientMedias', arrayCRUDMutations('clientMedias')),
		...mapFunctions('clientMedias', trash('clientMedias')),
		...mapFunctions('clientGames', arrayCRUDMutations('clientGames')),
		...mapFunctions('clientGames', trash('clientGames')),
		...mapFunctions('criterias', arrayCRUDMutations('criterias')),
		...mapFunctions('criterias', trash('criterias')),
		...mapFunctions('children', arrayCRUDMutations('children')),
		...mapFunctions('children', trash('children')),
		clearSpecific(state) {
			Vue.set(state, 'specificInfo', {})
		},
		setActive(state, { property = '', index = null }) {
			if (!property) return
			Vue.set(state.active, property, index)
		},
		updateCriteriaProperty(state, { property, data }) {
			if (!isNumber(state.active.criteria)) return
			Vue.set(state.criterias[state.active.criteria], property, data)
		},
	},
	actions: {
		...autoFormActions(),
		...objectActions(),
	},
	getters: {
		...autoFormGetters()
	}
}

const store_content = {
	namespaced: true,
	state: {
		meta: {
			modelMeta: null
		},
	},
	mutations: {
		...objectMutations(),
	},
	actions: {},
	getters: {
		...objectGetters()
	}
}

const store_matchingSet = {
	namespaced: true,
	state: {
		...stateAutoForm(),
		set: [],
		matchableElements: []
	},
	mutations: {
		...autoFormMutations(),
		...objectMutations(),
		...mapFunctions('set', arrayCRUDMutations('set')),
		...mapFunctions('set', trash('set')),
		...mapFunctions('matchableElements', arrayCRUDMutations('matchableElements')),
		...mapFunctions('matchableElements', trash('matchableElements')),
	},
	actions: {
		...autoFormActions(),
		...objectActions(),
	},
	getters: {
		...autoFormGetters()
	}
}

const store_matching = {
	namespaced: true,
	state: {
		...stateAutoForm(),
		layouts: [],
		layout: '',
		contentTexts: [],
		matchingSets: [],
		clientMedias: [],
		tabs: {
			active: 0,
			matchingSet: 0,
			medias: 1,
			contentTexts: 2,
		},
	},
	mutations: {
		...mapFunctions('contentTexts', arrayCRUDMutations('contentTexts')),
		...mapFunctions('matchingSets', arrayCRUDMutations('matchingSets')),
		...mapFunctions('clientMedias', arrayCRUDMutations('clientMedias')),
		...mapFunctions('contentTexts', trash('contentTexts')),
		...mapFunctions('matchingSets', trash('matchingSets')),
		...mapFunctions('clientMedias', trash('clientMedias')),
		...mapFunctions('layouts', arrayCRUDMutations('layouts')),
		...objectMutations(),
		...autoFormMutations(),
	},
	actions: {
		...mapFunctions('contentTexts', arrayActions()),
		...mapFunctions('matchingSets', arrayActions()),
		...mapFunctions('clientMedias', arrayActions()),
		...objectActions(),
		...autoFormActions()
	},
	getters: {
		id(state) {
			return state.id
		},
		...arrayGetters(),
		...autoFormGetters()
	}
}

const store_trivia = {
	namespaced: true,
	state: {
		...stateAutoForm(),
		layouts: [],
		layout: '',
		questions: [],
		clientMedias: [],
		settingsValues: {},
	},
	mutations: {
		...mapFunctions('questions', arrayCRUDMutations('questions')),
		...mapFunctions('clientMedias', arrayCRUDMutations('clientMedias')),
		...mapFunctions('questions', trash('questions')),
		...mapFunctions('clientMedias', trash('clientMedias')),
		...mapFunctions('layouts', arrayCRUDMutations('layouts')),
		...objectMutations(),
		...autoFormMutations(),
	},
	actions: {
		...mapFunctions('questions', arrayActions()),
		...mapFunctions('clientMedias', arrayActions()),
		...objectActions(),
		...autoFormActions()
	},
	getters: {
		id(state) {
			return state.id
		},
		settingsValues(state) {
			return state.settingsValues
		},
		...arrayGetters(),
		...autoFormGetters()
	}
}

const store_scramble = {
	namespaced: true,
	state: {
		...stateAutoForm(),
		layouts: [],
		layout: '',
		sentences: [],
		clientMedias: [],
		settingsValues: {},
	},
	mutations: {
		...mapFunctions('clientMedias', arrayCRUDMutations('clientMedias')),
		...mapFunctions('clientMedias', trash('clientMedias')),
		...mapFunctions('layouts', arrayCRUDMutations('layouts')),
		...mapFunctions('sentences', arrayCRUDMutations('sentences')),
		...mapFunctions('sentences', trash('sentences')),
		...objectMutations(),
		...autoFormMutations(),
	},
	actions: {
		...mapFunctions('sentences', arrayActions()),
		...mapFunctions('clientMedias', arrayActions()),
		...objectActions(),
		...autoFormActions()
	},
	getters: {
		id(state) {
			return state.id
		},
		settingsValues(state) {
			return state.settingsValues
		},
		...arrayGetters(),
		...autoFormGetters()
	}
}

const store_display = {
	namespaced: true,
	state: {
		...stateAutoForm(),
		layouts: [],
		layout: '',
		contentTexts: [],
		clientMedias: []
	},
	mutations: {
		...mapFunctions('contentTexts', arrayCRUDMutations('contentTexts')),
		...mapFunctions('clientMedias', arrayCRUDMutations('clientMedias')),
		...mapFunctions('contentTexts', trash('contentTexts')),
		...mapFunctions('clientMedias', trash('clientMedias')),
		...mapFunctions('layouts', arrayCRUDMutations('layouts')),
		...objectMutations(),
		...autoFormMutations(),
	},
	actions: {
		...mapFunctions('contentTexts', arrayActions()),
		...mapFunctions('clientMedias', arrayActions()),
		...objectActions(),
		...autoFormActions()
	},
	getters: {
		id(state) {
			return state.id
		},
		...arrayGetters(),
		...autoFormGetters()
	}
}

const store_question = {
	namespaced: true,
	state: {
		...stateAutoForm(),
		id: null,
		tags: [],
		tagsSelected: [],
		Fields: {},
	},
	mutations: {
		...autoFormMutations(),
		...mapFunctions('tags', arrayCRUDMutations('tags')),
		...mapFunctions('tagsSelected', arrayCRUDMutations('tagsSelected')),
		...mapFunctions('layouts', arrayCRUDMutations('layouts')),
		...objectMutations(),
		setTags(state, { data }) {
			if (!data) return
			Vue.set(state, 'tags', data)
		},
	},
	actions: {
		...autoFormActions(),
		...mapFunctions('tags', arrayActions()),
		...mapFunctions('tagsSelected', arrayActions()),
		...objectActions()
	},
	getters: {
		actualQuestion: state => state,
		...mapFunctions('tags', arrayGetters('tags')),
		...mapFunctions('tagsSelected', arrayGetters('tagsSelected'))
	}
}

const store_answers = {
	namespaced: true,
	state: {
		active: null,
		tags: [],
		tagsSelected: [],
		answers: []
	},
	mutations: {
		...arrayCRUDMutations('answers'),
		...trash('answers'),
		setActive(state, { index = null }) {
			Vue.set(state, 'active', index)
		},
		setSubElementActive(state, { id, property }) {
			state.answers
				.forEach(answer => {
					(answer[property] || [])
						.forEach(x => {
							Vue.set(x, 'active', (x.id || x.option_id) === id)
						})
				})
		},
		textDeleteElement(state, { index }) {
			state.answers[state.active].contentTexts.splice(index, 1)
		},
		updateProperty(state, { index, property, data }) {
			if (data === undefined || data === null) return
			if (
				!isNumber(state.active)
				&& !isNumber(index)
			) return
			Vue.set(state.answers[isNumber(state.active) ? state.active : index], property, data)
		},
		setTags(state, { data }) {
			if (!data) return
			Vue.set(state, 'tags', data)
		},
		tagsSetArray(state, { data }) {
			if (!isNumber(state.active)) return
			Vue.set(state, 'tags', data)
			Vue.set(state.answers[state.active], 'tags', data)
		},
		tagsSelectedSetArray(state, { data }) {
			if (!isNumber(state.active)) return
			Vue.set(state.answers[state.active], 'tagsSelected', data)
		},
		addMedia(state, { value }) {
			if (!isNumber(state.active)) return
			const { clientMedias = [] } = state.answers[state.active]
			Vue.set(state.answers[state.active], 'clientMedias', [...clientMedias, value])
		},
		addText(state, { value }) {
			if (!isNumber(state.active)) return
			const { contentTexts = [] } = state.answers[state.active]
			Vue.set(state.answers[state.active], 'contentTexts', [...contentTexts, value])
		},
		deletePropertyByIndex(state, { property, index }) {
			if (!isNumber(state.active)) return
			state.answers[state.active][property].splice(index, 1)
		}
	},
	actions: {
		...arrayActions(),
		updateProperty({commit}, { index, property, data }) {
			commit('updateProperty', { index, property, data })
		}
	},
	getters: {
		getAnswerByIndex: state => ({ index }) => state.answers[index],
		getPropertyByIndex: state => ({ index, property = '' }) => isNumber(index)
			? state.answers[index][property]
			: state.answers[property],
		getActive: state => {
			return state.answers[state.active]
		},
		getActiveIndex: state => state.active
	}
}

const store_medias = {
	namespaced: true,
	state: {
		...stateAutoForm(),
		parent: {},
		parentActive: false,
		children: [],
		clientMediaIds: [],
		media: null,
		file: null,
		schema: null,
		schemaId: null,
		done: () => {},
		func: () => {}
	},
	mutations: {
		...autoFormMutations(),
		...mapFunctions('clientMediasIds', arrayCRUDMutations('clientMediasIds')),
		...mapFunctions('clientMediasIds', trash('clientMediasIds')),
		...mapFunctions('children', arrayCRUDMutations('children')),
		...mapFunctions('children', trash('children')),
		...objectMutations(),
		setTags(state, { data }) {
			if (!data) return
			Vue.set(state, 'tags', data)
		},
		tagsSetArray(state, { data }) {
			Vue.set(state, 'tags', data)
		},
		tagsSelectedSetArray(state, { data }) {
			Vue.set(state, 'tagsSelected', data)
		},
	},
	actions: {
		...autoFormActions(),
		...objectActions(),
		...arrayActions(),
	},
	getters: {}
}

const store_game = {
	namespaced: true,
	state: {
		...stateAutoForm(),
		specificInfo: {},
		contentTexts: [],
		clientSection: [],
		clientMedias: [],
		origins: [],
		targets: [],
		steps: [],
		active: {
			reward: null,
			threshold: null,
			criteria: null,
			condition: null
		},
		executableInfo: [],
		executableQueries: {},
		optionValues: {},
	},
	mutations: {
		...objectMutations(),
		...autoFormMutations(),
		...mapFunctions('contentTexts', arrayCRUDMutations('contentTexts')),
		...mapFunctions('contentTexts', trash('contentTexts')),
		...mapFunctions('clientSection', arrayCRUDMutations('clientSection')),
		...mapFunctions('clientSection', trash('clientSection')),
		...mapFunctions('clientMedias', arrayCRUDMutations('clientMedias')),
		...mapFunctions('clientMedias', trash('clientMedias')),
		...mapFunctions('steps', arrayCRUDMutations('steps')),
		...mapFunctions('steps', trash('steps')),
		clearSpecific(state) {
			Vue.set(state, 'specificInfo', {})
		},
		setActive(state, { property = '', index = null }) {
			if (!property) return
			Vue.set(state.active, property, index)
		},
		updateRewardProperty(state, { property, data }) {
			if (!isNumber(state.active.reward)) return
			Vue.set(state.rewards[state.active.reward], property, data)
		},
		updateCriteriaProperty(state, { property, data }) {
			if (!isNumber(state.active.criteria)) return
			Vue.set(state.criterias[state.active.criteria], property, data)
		},
		updateThresholdProperty(state, { property, data }) {
			if (!isNumber(state.active.threshold)) return
			Vue.set(state.rewards[state.active.reward].thresholds[state.active.threshold], property, data)
		},
		thresholdsAddElement(state, { data }) {
			if (!data) return
			if (!state.rewards[state.active.reward].thresholds) state.rewards[state.active.reward].thresholds = []
			state.rewards[state.active.reward].thresholds.push(data)
			Vue.set(state.active, 'threshold', data)
		},
		thresholdsDeleteElement(state, { index }) {
			if (
				!isNumber(index)
				|| !isNumber(state.active.reward)
				|| !isNumber(state.active.threshold)
			) return
			state.rewards[state.active.reward].thresholds.splice(index, 1)
			Vue.set(state.active, 'threshold', null)
		},
		setConditionData(state, { data }) {
			if (!data) return
			const { condition: index } = state.active
			if (!isNumber(index)) return
			const { option_id, option_text, tooltip, params, type } = data
			Vue.set(state.conditions[index], 'btn', {
				option_id,
				option_text,
				tooltip
			})
			Vue.set(state.conditions[index], 'params', params)
			Vue.set(state.conditions[index], 'type', type)
		},
	},
	actions: {
		...autoFormActions(),
		...objectActions()
	},
	getters: {
		...autoFormGetters(),
		actualReward: state => isNumber(state.active.reward) && state.rewards.length ? state.rewards[state.active.reward] : null,
		getRewards: state => state.rewards
	}
}

const store_columnMedias = {
	namespaced: true,
	state: {
		slug: '',
		tagsSelected: [],
		clientMediaIds: []
	},
	mutations: {
		...autoFormMutations(),
		...arrayCRUDMutations('clientMediaIds'),
		...trash('clientMediaIds'),
		...objectMutations(),
		setTags(state, { data }) {
			if (!data) return
			Vue.set(state, 'tags', data)
		},
		tagsSetArray(state, { data }) {
			Vue.set(state, 'tags', data)
		},
		tagsSelectedSetArray(state, { data }) {
			Vue.set(state, 'tagsSelected', data)
		},
	},
	actions: {
		...autoFormActions(),
		...objectActions(),
		...arrayActions(),
	},
	getters: {}
}

const store_loot = {
	namespaced: true,
	state: {
		...stateAutoForm(),
		name: '',
		countable: false,
		clientMedias: [],
		contentTexts: [],
		hidden: false,
	},
	mutations: {
		...autoFormMutations(),
		...objectMutations(),
		...mapFunctions('clientMedias', arrayCRUDMutations('clientMedias')),
		...mapFunctions('clientMedias', trash('clientMedias')),
		...mapFunctions('contentTexts', arrayCRUDMutations('contentTexts')),
		...mapFunctions('contentTexts', trash('contentTexts')),
		setTags(state, { data }) {
			if (!data) return
			Vue.set(state, 'tags', data)
		},
		tagsSetArray(state, { data }) {
			Vue.set(state, 'tags', data)
		},
		tagsSelectedSetArray(state, { data }) {
			Vue.set(state, 'tagsSelected', data)
		}
	},
	actions: {
		...autoFormActions(),
		...objectActions(),
	},
	getters: {}
}

const store_message = {
	namespaced: true,
	state: {
		...stateAutoForm(),
	},
	mutations: {
		...autoFormMutations(),
		...objectMutations(),
	},
	actions: {
		...autoFormActions(),
		...objectActions(),
	},
	getters: {}
}

const store_filter = {
	namespaced: true,
	state: {
		...stateAutoForm(),
		name: null
	},
	mutations: {
		...objectMutations(),
		...autoFormMutations(),
	},
	actions: {
		...autoFormActions(),
		...objectActions(),
	},
}

const store_file = {
	namespaced: true,
	state: {
		...stateAutoForm(),
		specificInfo: {},
		clientSection: [],
		clientMedias: [],
		contentTexts: [],
		origins: [],
		targets: [],
		active: {
			reward: null,
			threshold: null,
			criteria: null,
			condition: null
		},
	},
	mutations: {
		...objectMutations(),
		...autoFormMutations(),
		...mapFunctions('clientSection', arrayCRUDMutations('clientSection')),
		...mapFunctions('clientSection', trash('clientSection')),
		...mapFunctions('clientMedias', arrayCRUDMutations('clientMedias')),
		...mapFunctions('clientMedias', trash('clientMedias')),
		...mapFunctions('contentTexts', arrayCRUDMutations('contentTexts')),
		...mapFunctions('contentTexts', trash('contentTexts')),
		clearSpecific(state) {
			Vue.set(state, 'specificInfo', {})
		},
		setActive(state, { property = '', index = null }) {
			if (!property) return
			Vue.set(state.active, property, index)
		},
		updateRewardProperty(state, { property, data }) {
			if (!isNumber(state.active.reward)) return
			Vue.set(state.rewards[state.active.reward], property, data)
		},
		updateCriteriaProperty(state, { property, data }) {
			if (!isNumber(state.active.criteria)) return
			Vue.set(state.criterias[state.active.criteria], property, data)
		},
		updateThresholdProperty(state, { property, data }) {
			if (!isNumber(state.active.threshold)) return
			Vue.set(state.rewards[state.active.reward].thresholds[state.active.threshold], property, data)
		},
		thresholdsAddElement(state, { data }) {
			if (!data) return
			if (!state.rewards[state.active.reward].thresholds) state.rewards[state.active.reward].thresholds = []
			state.rewards[state.active.reward].thresholds.push(data)
			Vue.set(state.active, 'threshold', data)
		},
		thresholdsDeleteElement(state, { index }) {
			if (
				!isNumber(index)
				|| !isNumber(state.active.reward)
				|| !isNumber(state.active.threshold)
			) return
			state.rewards[state.active.reward].thresholds.splice(index, 1)
			Vue.set(state.active, 'threshold', null)
		},
		setConditionData(state, { data }) {
			if (!data) return
			const { condition: index } = state.active
			if (!isNumber(index)) return
			const { option_id, option_text, tooltip, params, type } = data
			Vue.set(state.conditions[index], 'btn', {
				option_id,
				option_text,
				tooltip
			})
			Vue.set(state.conditions[index], 'params', params)
			Vue.set(state.conditions[index], 'type', type)
		},
	},
	actions: {
		...autoFormActions(),
		...objectActions()
	},
	getters: {
		...autoFormGetters(),
		actualReward: state => isNumber(state.active.reward) && state.rewards.length ? state.rewards[state.active.reward] : null,
		getRewards: state => state.rewards
	}
}

const store_form = {
	namespaced: true,
	state: {
		...stateAutoForm(),
		clientMedias: [],
		formElements: [],
		formatAs: null,
		formattedElements: [],
		formInputTags: [],
		type: null,
		layouts: [],
		selects: [],
		inputs: [],
		active: {
			formElement: null,
		},
		saved: false,
		_ref: null,
		tabs: {
			active: 0,
			elements: {
				active: 0,
				media: 0,
				text: 1,
				select: 2,
				input: 3,
				number: 4,
				password: 5,
				accept: 6,
				deck: 7,
				container: 8,
			}
		},
	},
	mutations: {
		...autoFormMutations(),
		...objectMutations(),
		...mapFunctions('contentTexts', arrayCRUDMutations('contentTexts')),
		...mapFunctions('contentTexts', trash('contentTexts')),
		...mapFunctions('clientMedias', arrayCRUDMutations('clientMedias')),
		...mapFunctions('clientMedias', trash('clientMedias')),
		...mapFunctions('formElements', arrayCRUDMutations('formElements')),
		...mapFunctions('formElements', trash('formElements')),
		...mapFunctions('formattedElements', arrayCRUDMutations('formattedElements')),
		...mapFunctions('formattedElements', trash('formattedElements')),
		...mapFunctions('selects', arrayCRUDMutations('selects')),
		...mapFunctions('selects', trash('selects')),
		...mapFunctions('inputs', arrayCRUDMutations('inputs')),
		...mapFunctions('inputs', trash('inputs')),
		...mapFunctions('layouts', arrayCRUDMutations('layouts')),
		deleteSubElement(state, { parent, index }) {
			const { entry: { subElements } = {} } = parent
			if (!subElements) return
			subElements.splice(index, 1)
			Vue.set(parent.entry, 'subElements', subElements)
			Vue.set(parent, 'saved', false)
		},
		// deleteSelectableElement(state, { index }) {
		// 	const { formElement } = state.active
		// 	state.formElements[formElement].entry.selectableElements.splice(index, 1)
		// },
		tagsSetArrayOverride(state, { element, data }) {
			Vue.set(state, 'formInputTags', data)
			// Vue.set(state.formElements[index], 'saved', false)
		},
		tagsSelectedSetArrayOverride(state, { element, data }) {
			const { active } = state
			if (!element.entry) {
				Vue.set(element, 'entry', {})
			}
			Vue.set(element.entry, 'tagsSelected', data)
			Vue.set(active, 'saved', false)
		},
		updateInput(state, { value, entryType, entryId }) {
			const { tags } = value
			if (tags) Object.assign(value, { tagsSelected: tags })
			Vue.set(state.active, 'entry', value)
			Vue.set(state.active, 'saved', false)
			Vue.set(state.active, 'entryType', entryType)
			Vue.set(state.active, 'entryId', entryId)
		},
		setTags(state, { data, property = null }) {
			if (!data) return
			if (property) Vue.set(state, 'formInputTags', data)
			else Vue.set(state, 'tags', data)
		},
		setElementSaved(state, { element, saved }) {
			if (!element) return
			Vue.set(element, 'saved', saved)
		},
		setFormElementProperty(state, { property = null, value, element, root = false }) {
			if (!property || !element) return
			if (!element.entry) {
				Vue.set(element, 'entry', {})
			}
			Vue.set(root
				? element
				: element.entry,
			property, value)
		},
		setActive(state, { element }) {
			if (!element) return
			Vue.set(state, 'active', element)
		},
		setSubElement(state, {activeElement, value}) {
			let {entryTypeActiveElement} = activeElement
			let {entryTypeValue} = value
			if (entryTypeActiveElement !== entryTypeValue) return
			Vue.set(activeElement, 'entry', value.entry)
		},
		addSubElement(state, { activeElement, value }) {
			let { entry: { subElements = [] } = {} } = activeElement
			if (!activeElement.entry) {
				Vue.set(activeElement, 'entry', {})
			}
			subElements = [ ...subElements, value ]
			Vue.set(activeElement.entry, 'subElements', subElements)
		},
		addSelectableElement(state, { activeElement, value }) {
			let { entry: { selectableElements = [] } = {} } = activeElement
			if (!activeElement.entry) {
				Vue.set(activeElement, 'entry', {})
			}
			selectableElements = [ ...selectableElements, value ]
			Vue.set(activeElement.entry, 'selectableElements', selectableElements)
		},
	},
	actions: {
		...autoFormActions(),
		...objectActions(),
	},
	getters: {}
}

const store_tutorial = {
	namespaced: true,
	state: {
		...stateAutoForm(),
	},
	mutations: {
		...autoFormMutations(),
		...objectMutations(),
	},
	actions: {
		...autoFormActions(),
		...objectActions()
	},
	getters: {}
}

const store_group = {
	namespaced: true,
	state: {
		...stateAutoForm(),
		abilities: [],
		abilitiesSelected: [],
		name: '',
	},
	mutations: {
		...autoFormMutations(),
		...objectMutations(),
		...mapFunctions('abilities', arrayCRUDMutations('abilities')),
		...mapFunctions('abilitiesSelected', arrayCRUDMutations('abilitiesSelected')),
	},
	actions: {
		...autoFormActions(),
		...objectActions()
	},
	getters: {}
}

const store_deck = {
	namespaced: true,
	state: {
		...stateAutoForm(),
		clientMedias: [],
		contentTexts: [],
		clientCategory: [],
		clientCard: [],
		parent: [],
	},
	mutations: {
		...autoFormMutations(),
		...objectMutations(),
		...mapFunctions('clientMedias', arrayCRUDMutations('clientMedias')),
		...mapFunctions('clientMedias', trash('clientMedias')),
		...mapFunctions('contentTexts', arrayCRUDMutations('contentTexts')),
		...mapFunctions('contentTexts', trash('contentTexts')),
		...mapFunctions('clientCategory', arrayCRUDMutations('clientCategory')),
		...mapFunctions('clientCategory', trash('clientCategory')),
		...mapFunctions('clientCard', arrayCRUDMutations('clientCard')),
		...mapFunctions('clientCard', trash('clientCard')),
		...mapFunctions('parent', arrayCRUDMutations('parent')),
		...mapFunctions('parent', trash('parent')),
		setTags(state, { data }) {
			if (!data) return
			Vue.set(state, 'tags', data)
		},
	},
	actions: {
		...autoFormActions(),
		...objectActions()
	},
	getters: {}
}
const store_category = cloneDeep(store_deck)
const store_card = cloneDeep(store_deck)

const stack = 'stack'
const Stack = 'Stack'
const client = 'client'
const Clients = 'Clients'

export default new Vuex.Store({
	modules: {
		store_answers,
		store_card,
		store_category,
		store_clients,
		store_columnMedias,
		store_content,
		store_contentTexts,
		store_deck,
		store_display,
		store_file,
		store_filter,
		store_form,
		store_game,
		store_group,
		store_loot,
		store_matching,
		store_matchingSet,
		store_medias,
		store_message,
		store_module,
		store_question,
		store_questions,
		store_scramble,
		store_section,
		store_steps,
		store_textContent,
		store_trivia,
		store_tutorial,
	},
	state: {
		accessToken: null,
		isRefreshToken: false,
		toolbarOptions: [
			{ title: Clients, option: client },
			{ title: Stack, option: stack }
		],
		selectedToolbarOption: { title: Clients, option: client },
		deleteDialogCallback: null,
		deleteElementName: '',
		dialogText: '',
		dialogCallback: null,
		confirmText: '',
		confirmCallback: null,
		error: {
			value: false,
			type: null,
			dismissible: true,
			icon: null,
			transition: '',
			outline: true,
			color: '',
			message: ''
		},
		client: null,
		language: LANGUAGE.getData(),
		toolbarMessage: ''
	},
	getters: {
		client: state => state.client,
		error: state => state.error,
		getDialogText: state => state.dialogText,
		isClient: state => !!state.client,
		isToolbarClient: state => state.selectedToolbarOption.option === client,
		isToolbarStack: state => state.selectedToolbarOption.option === stack,
		language: state => state.language,
		selectedToolbarOption: state => state.selectedToolbarOption,
		toolbarMessage: state => state.toolbarMessage,
		toolbarOptions: state => state.toolbarOptions,
		isRefreshToken: state => state.isRefreshToken,
		getAccessToken: state => state.accessToken,
		isDialog: state => !!state.dialogText,
		isConfirm: state => !!state.confirmText,
	},
	mutations: {
		...objectMutations(),
		setIsRefreshToken(state, boolean) {
			Vue.set(state, 'isRefreshToken', boolean)
		},
		removeAccessToken(state) {
			Vue.set(state, 'accessToken', null)
		},
		setAccessToken(state, { accessToken }) {
			Vue.set(state, 'accessToken', accessToken)
		},
		setDeleteDialogCallback(state, { callback, elementName = '' }) {
			state.deleteDialogCallback = callback
			state.deleteElementName = elementName
		},
		error(state, {message, options = {} }) {
			const { type = 'error' } = options
			Object.assign(state.error, {
				message,
				value: true,
				color: type,
				type: type,
				dismissible: true
			})
			setTimeout(() => {
				state.error.value = false
			}, 4000)
		},
		success(state, {message}) {
			const type = 'success'
			Object.assign(state.error, {
				message,
				value: true,
				color: type,
				type: type,
				dismissible: false
			})
			setTimeout(() => {
				state.error.value = false
			}, 3000)
		},
		setClient(state, client) {
			state.client = client
		},
		changeLanguage(state, language) {
			if (state.language !== language) {
				LANGUAGE.setData({ data: language })
				state.language = language
			}
		},
		toolbarMessage(state, message) {
			setTimeoutOn.call(state, 'toolbarMessage')(message, '')
		},
		setDialog(state, { callback = null, text = null }) {
			Vue.set(state, 'dialogText', text)
			Vue.set(state, 'dialogCallback', callback)
		},
		setConfirm(state, { callback = null, text = null }) {
			Vue.set(state, 'confirmText', text)
			Vue.set(state, 'confirmCallback', callback)
		}
	},
	actions: {
		setConfirmCallback({ commit }, { callback, text }) {
			commit('setConfirm', { callback, text })
		},
		setConfirm({ commit }, { callback, text }) {
			commit('setConfirm', { callback, text })
		},
		setDialog({ commit }, { callback, text }) {
			commit('setDialog', { callback, text })
		},
		setDeleteDialogCallback({ commit }, { callback, elementName = '' }) {
			commit('setDeleteDialogCallback', { callback, elementName })
		},
		resetDeleteDialogCallback({ commit }) {
			commit('setDeleteDialogCallback', { callback: null })
		},
		register({commit}, credentials) {
			const {nom, email, password} = credentials
			if (!nom || !email || !password) return
			this.$apollo
				.mutate({
					mutation: REGISTER_USER_MUTATION,
					variables: {
						nom,
						email,
						password
					}
				})
				.then(result => {
					// const id = result.data.signinUser.user.id
					// const token = result.data.signinUser.token
					// this.saveUserData(id, token)
				})
				.catch(error => {
					alert(error)
				})
		},
		loginSuccessful({ commit }, { accessToken, refreshToken }) {
			if (!accessToken || !refreshToken) return
			REFRESH_TOKEN.setData({ data: refreshToken })
			commit('setAccessToken', { accessToken })
		},
		removeAccessTokens({ commit }) {
			REFRESH_TOKEN.clearData()
			commit('removeAccessToken')
		},
		logout({commit}) {
			console.log('LOG OUT')
		},
		setClient({commit}, client) {
			if (!client || !client.identifier || !client.id) return
			CLIENT.setData({data: client.id})
			commit('setClient', client)
		},
		setLanguage({commit}) {
			if (!LANGUAGE.getData()) {
				commit('changeLanguage', 'en')
			}
			Vue.i18n.set(this.state.language)
		},
		changeLanguage({commit}, language) {
			if (language) {
				commit('changeLanguage', language)
			}
		}
	}
})
