import Vue from 'vue'
import VueApollo from 'vue-apollo'
import {createApolloClient/*, restartWebsockets*/} from 'vue-cli-plugin-apollo/graphql-client'
import {InMemoryCache, IntrospectionFragmentMatcher} from "apollo-cache-inmemory"
import introspectionQueryResultData from '@/apollo/fragmentTypes.json'
// import {ApolloClient} from 'apollo-client'
import {ApolloLink} from "apollo-link"
// import {split, from} from 'apollo-link'
import {/*createHttpLink,*/ HttpLink} from 'apollo-link-http'
import {setContext} from 'apollo-link-context'
import {REFRESH_TOKEN} from './JS/refreshToken'
import {CLIENT} from './JS/client'
import {LANGUAGE as LANG} from './JS/Language'
import store from "@/store"

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item

// export const AUTH_TOKEN = USER.getDefinition()
// const TOKEN_TYPE = 'token-type'
export const AUTH_TOKEN = 'Admin-Authorization'
const CLIENT_ID = CLIENT.getDefinition()
const LANGUAGE = LANG.getDefinition() || 'fr'
let APOLLO_CLIENT

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || ''
const httpLink = new HttpLink({
	uri: httpEndpoint,
})
const authLink = setContext((_, {headers}) => {
	return {
		headers: {
			[AUTH_TOKEN]: store.getters.isRefreshToken
				? REFRESH_TOKEN.getData()
				: store.getters.getAccessToken,
			[CLIENT_ID]: CLIENT.getData() || '',
			[LANGUAGE]: LANG.getData() || '',
		}
	}
})
const fragmentMatcher = new IntrospectionFragmentMatcher({
	introspectionQueryResultData
})
const link = ApolloLink.from([
	authLink,
	httpLink
])
// Config
const defaultOptions = {
	httpLinkOptions: {
		credentials: 'same-origin',
	},
	// You can use `https` for secure connection (recommended in production)
	httpEndpoint,
	// You can use `wss` for secure connection (recommended in production)
	// Use `null` to disable subscriptions
	wsEndpoint: null, //process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
	// LocalStorage token
	tokenName: AUTH_TOKEN,
	// Enable Automatic Query persisting with Apollo Engine
	persisting: false,
	// Use websockets for everything (no HTTP)
	// You need to pass a `wsEndpoint` for this to work
	websocketsOnly: false,
	// Is being rendered on the server?
	ssr: false,
	
	// Override default apollo link
	// note: don't override httpLink here, specify httpLink options in the
	// httpLinkOptions property of defaultOptions.
	defaultHttpLink: false,
	link,
	
	// httpLinkOptions,
	
	// Override default cache
	// cache: new InMemoryCache({
	// 	addTypename: false
	// }),
	cache: new InMemoryCache({
		fragmentMatcher
	}),
	
	// Override the way the Authorization header is set
	// getAuth: (tokenName = AUTH_TOKEN) => {
	//   return localStorage.getItem(tokenName) || ''
	// }
	
	// Additional ApolloClient options
	// apollo: { ... }
	
	// Client local data (see apollo-link-state)
	// clientState: { resolvers: { ... }, defaults: { ... } }
}

// Call this in the Vue app file
export function createProvider(options = {}) {
	// Create apollo client
	//console.log("CREATE PROVIDER CALLED")
	const {apolloClient, wsClient} = createApolloClient({
		...defaultOptions,
		...options,
	})
	
	apolloClient.wsClient = wsClient
	
	// Create vue apollo provider
	const apolloProvider = new VueApollo({
		defaultClient: apolloClient,
		defaultOptions: {
			$query: {
				fetchPolicy: 'cache-and-network',
			},
		},
		errorHandler(error) {
			// eslint-disable-next-line no-console
			console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
		},
	})
	APOLLO_CLIENT = apolloClient
	return apolloProvider
}
