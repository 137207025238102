import {Error} from "@/JS/Error"
import {loading} from '@/JS/StackAdminUtils/utils'

export async function sendMutationWithMessage({mutation, variables, update, message}) {
	loading.call(this, true)
	const result = await sendMutation.call(this.$apolloProvider.defaultClient, {mutation, variables, update})
	loading.call(this, false, message)
	return result
}

export async function sendMutation({mutation, variables, update}) {
	const result = await this.mutate({
		mutation,
		variables,
		update
	}).catch(error => {
		throw Error.graphQL(error)
	})
	if (!result) {
		return null
	}
	const {response} = result.data
	if (!response) {
		return null
	}
	return response
}

export async function sendQuery({query, variables = {}}) {
	const result = await this.query({
		fetchPolicy: 'network-only',
		query,
		variables
	}).catch(error => {
		throw Error.graphQL(error)
	})
	if (!result) {
		return null
	}
	const {response} = result.data
	if (!response) {
		return null
	}
	return response
}
